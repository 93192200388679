import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnChanges, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, LayoutUtilsService, TokBoxService } from '../../../shared/services';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';

import { MatPaginator, MatTableDataSource, MatSort, MatDialog, MatDialogRef } from '@angular/material';
import { ModalIframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(-100%)',
        display: 'none',
        opacity: 0,
      })),
      state('out', style({
        transform: 'translateY(0%)',
        display: 'initial',
        opacity: 1
      })),
      transition('in => out', animate('500ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out'))
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)', display: 'hidden' }),
      //   animate('3000ms ease-in', style({ transform: 'translateY(0%)' }))
      // ]),
      // transition('* => void', [
      //   animate('4000ms ease-in', style({ transform: 'translateY(-100%)' }))
      // ])
    ])
  ]
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public orgType = environment.orgType;
  public lockSite = environment.lockSite;
  public hasListingDirectory = environment.hasListingDirectory;
  public permission: any[] = [environment.customKeys.roleEdit, environment.customKeys.roleAdmin];
  public viewPermission: any[] = [environment.customKeys.roleView];
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public donateLink: string = undefined;
  public pushRightClass: string;
  public userType: string = 'default';
  isDnD: boolean = false;
  iframeDialog: MatDialogRef<any, any>;
  highlightDirectory: boolean = undefined;
  _isOpenGlobalChat: boolean = false;
  _isOpenAgenda: boolean = false;
  drawerState: string = 'in';
  // lastChatMessage: any = { message: '' };
  // videoChatInvite: boolean = false;
  ringtoneAudio: any = undefined;
  ringTimeOut: any = undefined;
  goodConnection: boolean = true;
  isMobile: any;

  @Input() highlightNotifications: boolean = false;
  @Input() showProductionLabel: any = undefined;
  @Input() showExit: boolean = false;
  @Input() roomData: any = undefined;
  @Input() sessionData: any = undefined;
  @Input() pushedTileLink: string = undefined;
  @Input() showEndSession: boolean = false;

  @Output() highlightNotificationsChange = new EventEmitter<boolean>();
  @Output() onendSession = new EventEmitter<any>();

  @ViewChild('ringStream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.ringtoneAudio = ref.nativeElement;
  }

  constructor(
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private zone: NgZone, public opentokService: TokBoxService, private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private deviceService: DeviceDetectorService) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.isMobile = this.deviceService.isMobile();
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          if (this.selectedUser.hasOwnProperty('chat_dnd'))
            this.isDnD = this.selectedUser['chat_dnd'];
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.donateLink = data.donate;
        }
      })
    );

    // setTimeout(() => { this.drawerState = 'out' }, 1000);
    // setTimeout(() => { this.drawerState = 'in' }, 5000);

    this.subscriptions.push(this.opentokService.unreadChats.subscribe((data) => {
      if (data) {
        if (this.opentokService.chatPrivatelyWith != data.unread.id && this.opentokService.userFlags.hasOwnProperty(data.unread.id))
          this.opentokService.userFlags[data.unread.id].unread = true;

        this.opentokService.lastChatMessage = { message: data.unread.sender + ': ' + data.unread.message, id: data.unread.id };
        // this.lastChatMessageId = data.unread.id;
      }
      let findUnread = false;
      //console.log('keys', Object.keys(this.opentokService.userFlags));
      Object.keys(this.opentokService.userFlags).forEach(key => {
        //console.log(key, this.opentokService.userFlags[key])
        if (this.opentokService.userFlags[key].hasOwnProperty('unread') && this.opentokService.userFlags[key].unread == true)
          findUnread = true;
      });

      if (findUnread) {
        this.highlightDirectory = true;
        if (data && !this.opentokService.userFlags[data.unread.id].selected) {
          this.slideDrawer();
        }
      }
      else
        this.highlightDirectory = false;
    }));

    this.subscriptions.push(this.opentokService._isOpenGlobalChat.subscribe((value) => {
      if (value != undefined) {
        this._isOpenGlobalChat = value;
      }
    }));

    this.subscriptions.push(this.opentokService._isOpenAgenda.subscribe((value) => {
      if (value != undefined) {
        this._isOpenAgenda = value;
      }
    }));

    this.subscriptions.push(this.opentokService.connectionHealth.subscribe((value) => {
      if (value != undefined) {
        this.goodConnection = value;
      }
    }));

    this.subscriptions.push(this.opentokService._dndChat.subscribe((value) => {
      if (value != undefined) {
        this.isDnD = value;
        this.opentokService.sendSignal('dnd', this.isDnD + '', this.opentokService.globalSession);
        this.detectChanges();
      }
    }));

    this.subscriptions.push(this.opentokService._isTextChat.subscribe((value) => {
      if (value != undefined) {
        this.opentokService.sendSignal('showTextChat', value + '', this.opentokService.globalSession);
      }
    }));

    this.subscriptions.push(this.opentokService._isVideoChat.subscribe((value) => {
      if (value != undefined) {
        this.opentokService.sendSignal('showVideoChat', value + '', this.opentokService.globalSession);
      }
    }));

    this.subscriptions.push(this.opentokService._hideDrawer.subscribe((value) => {
      if (value != undefined) {
        if (value) {
          this.pauseRingTone();
          this.closeDrawer();
        }
        else {
          this.playRingTone();
          this.slideDrawer();
        }
      }
    }));

    this.subscriptions.push(this.opentokService._playRingTone.subscribe((data) => {
      if (data != undefined) {
        if (data)
          this.playRingTone();
        else
          this.pauseRingTone();
      }
    }));

    this.subscriptions.push(this.opentokService._videoCallResponse.subscribe((event) => {
      if (event != undefined) {
        if (event['data'] == '0' || event['data'] == '-1') {
          this.pauseRingTone();
          this.opentokService.videoChatInvite = false;
          this.closeDrawer();
          this.opentokService.lastChatMessage = { message: '' };
        }
      }
    }));

    this.subscriptions.push(this.opentokService.userListJoinedGlobally.subscribe((data) => {
      if (data) {
        if (!data.joined && this.opentokService.lastChatMessage && this.opentokService.lastChatMessage.id == data.user['userId'] && this.opentokService.videoChatInvite) {
          this.closeDrawer();
          this.pauseRingTone();
        }
      }
    }));

    // if (this.route.snapshot['_routerState'].url !== '/book')
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    // this.subscriptions = [];
    // this.drawerState = "in";
    // this.isDnD = undefined;
    // this.highlightDirectory = undefined;
    // this._isOpenGlobalChat = false;
    // this._isOpenAgenda = false;
    // this.lastChatMessage = { message: '' };
    // this.videoChatInvite = false;
    // this.ringtoneAudio = undefined;
    // this.ringTimeOut = undefined;
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  goHome() {
    this.router.navigate(['/rooms/list']);
  }

  onLoggedout() {
    this.requestService.logOutApi();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
  // public viewProfile(){
  //   const dialogRef = this.dialog.open(ModalUserDialogComponent, {
  //       width: '1200px',
  //       data: {
  //         title: 'My Profile',
  //         data: this.selectedUser,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }
  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1000px',
      data: {
        dataType: 'resource/user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }
  endSession() {
    this.onendSession.emit(true);
  }
  showFrame(iframeLink: string) {
    if (iframeLink) {
      // this.zone.run(() => {
      if (this.iframeDialog) {
        this.iframeDialog.close();
      }
      this.iframeDialog = this.dialog.open(ModalIframeDialogComponent, {
        data: {
          data: iframeLink,
          className: 'dialog-frame',
          iframeHeight: '280px'
        }
      });
      this.iframeDialog.disableClose = true;

      // });
      this.iframeDialog.afterClosed().subscribe(() => {
        this.highlightNotifications = false;
        this.highlightNotificationsChange.emit(false);
      });
    }
  }

  gotoBook() {
    this.router.navigate(['/book/']);
  }

  openGlobalChat() {
    this._isOpenGlobalChat = !this._isOpenGlobalChat;
    this.opentokService.isOpenGlobalChat.next(this._isOpenGlobalChat);
    // debugger;
    if (this.opentokService.chatPrivatelyWith && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].hasOwnProperty('unread') && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].unread && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].hasOwnProperty('selected') && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].selected) {
      this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].unread = false;
      this.opentokService.unreadChats.next(null);
    }
    //console.log('_isOpenGlobalChat', this._isOpenGlobalChat)
    this.detectChanges();
  }

  openAgenda() {
    this.router.navigateByUrl('/agenda');
    // this._isOpenAgenda = !this._isOpenAgenda;
    // this.opentokService.isOpenAgenda.next(this._isOpenAgenda);
    // this.detectChanges();
  }
  openSponsors() {
    this.router.navigateByUrl('/sponsors');
    // this._isOpenAgenda = !this._isOpenAgenda;
    // this.opentokService.isOpenAgenda.next(this._isOpenAgenda);
    // this.detectChanges();
  }
  openDonate() {
    if (this.donateLink && this.donateLink !== '') {
      window.open(this.donateLink, '_blank');
    }
  }

  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleDnD() {
    this.requestService.saveData('resource/user', { _id: this.selectedUser['_id'], chat_dnd: !this.isDnD }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        this.isDnD = !this.isDnD;
        this.opentokService.sendSignal('dnd', this.isDnD + '', this.opentokService.globalSession);
        this.requestService.updateUserData('chat_dnd', this.isDnD);
        this.detectChanges();
        //console.log(this.selectedUser)
        this.layoutUtilsService.showNotification(this.translate.instant('Preferences Saved'), this.translate.instant('Dismiss'));
      }
      // this.loading = false;
    }, true);
  }

  closeDrawer() {
    this.drawerState = 'in';
  }

  chatWithAttendee() {
    this.opentokService.chatPrivatelyWithSubject.next({ id: this.opentokService.lastChatMessage.id });
  }

  private slideDrawer() {
    this.drawerState = 'out';
    setTimeout(() => { this.drawerState = 'in' }, 3000);
    this.detectChanges();
  }

  playRingTone() {
    // this.ringtoneAudio = new Audio();
    // this.ringtoneAudio.src = "assets/files/phone_ringing.mpeg";
    // this.ringtoneAudio.loop = true;
    // this.ringtoneAudio.autoplay = false;
    // this.ringtoneAudio.id = "ringing-tone";
    //console.log('ringing', this.ringtoneAudio)
    this.ringtoneAudio.oncanplay = () => {
      if (!this.ringTimeOut)
        this.ringTimeOut = setTimeout(() => this.declineCall(), 12000);
    };
    this.ringtoneAudio.load();
    this.ringtoneAudio.play();
  }

  pauseRingTone() {
    if (this.ringtoneAudio) {
      this.ringtoneAudio.pause();
      // this.ringtoneAudio = undefined;
      clearTimeout(this.ringTimeOut);
      this.ringTimeOut = undefined;
    }
  }

  declineCall() {
    // this.opentokService._hideCallActions.next({ hide: true, id: this.lastChatMessage.id });
    this.opentokService.onGoingCall = undefined;
    if (this.opentokService.userFlags.hasOwnProperty(this.opentokService.lastChatMessage.id)) {
      this.opentokService.userFlags[this.opentokService.lastChatMessage.id].unread = false;
      this.opentokService._declineCall.next({ userId: this.opentokService.lastChatMessage.id, connection: this.opentokService.lastChatMessage.connection });
    }
    this.opentokService.unreadChats.next(null);
  }

  answerCall() {
    if (this.opentokService.userFlags.hasOwnProperty(this.opentokService.lastChatMessage.id)) {
      this.chatWithAttendee();
      this.opentokService.userFlags[this.opentokService.lastChatMessage.id].unread = false;
      this.opentokService.unreadChats.next(null);
      this.opentokService._answerCall.next({ userId: this.opentokService.lastChatMessage.id, connection: this.opentokService.lastChatMessage.connection });
    }
  }
}
