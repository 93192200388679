export const environment = {
  production: false,
  lockSite: false,
  serverTileUrl: 'https://stellar.staging.ili.ms/',
  serverUrl: 'https://api.staging.ili.ms/api/',
  serverUrlNoSlash: 'https://api.staging.ili.ms/api',
  vimeoUrl: 'https://player.vimeo.com/video/',
  serverHostUrl: 'https://stellar.classroom.interactivelife.me',
  serverredirectUrl: 'https://www.interactivelife.com/',
  aliveAppIntervalMinutes: 90, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 30,
  hasListingDirectory: true,
  identityName: 'School',
  serverName: 'Stellar Classroom',
  orgType: 'school',
  orgSubType: 'school',
  subServerName: 'Stellar Classroom',
  profileImageURL: '',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
    roleEdit: '5ee761c8d3123a0ef0d8cb61',// Editor
  },
  customKeysView: {
    Admin: 'Admin',
    Student: 'View',
    Teacher: 'Edit',
  },
  tokBox: {
    apiKey: '46643372'
  }
};
