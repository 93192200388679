import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { EmptyComponent } from './empty/empty.component';
import { MobileComponent } from './mobile/mobile.component';

const routes: Routes = [
    // {
    //     path: '',
    //     loadChildren: './layout/layout.module#LayoutModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: '',
        loadChildren: './pages/pages.module#PagesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'login/:dialog',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'mobile',
        component: MobileComponent
    },
    // {
    //     path: 'register',
    //     loadChildren: './registration/registration.module#RegistrationModule'
    // },
    { path: 'empty', component: EmptyComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true,onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
