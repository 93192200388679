import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { fromEvent, BehaviorSubject, merge, Subscription, interval } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-tile-answers',
	templateUrl: './tile-answers.component.html',
  styleUrls: ['./tile-answers.component.scss']
})
export class TileAnswersComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
	public errorMessage: string = '';
  public loading: boolean = false;
	private repeatIntervalSeconds: number = environment.repeatValidateSeconds;
	public selectedOrganization: string = "-1";
	public dataList: any []= [];
	public dataListView: any = {};
	public dataListViewKeys: any[] = [];
	@Input() lectureId: string = undefined;
  public _selectedData: any = undefined;
	@Input()
  set selectedData(selectedData: any) {
		this._selectedData = selectedData;
    if(this.lectureId && selectedData && selectedData.hasOwnProperty('_id')){
			this.loadData();
		}else{
			this.dataList = [];
		}
  }
  get selectedData(): any {
    return this._selectedData;
  }
	constructor(
		private requestService: RequestService, private translate: TranslateService, private sanitizer: DomSanitizer,
    private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService) {
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
				}
			})
		);
		this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe( (x) => {
			// console.log('TileAnswersComponent', this.lectureId, this.selectedData);
		   	if(this.lectureId && this.selectedData && this.selectedData.hasOwnProperty('_id')){
				 	this.loadData();
				}else{
					this.dataList = [];
					this.dataListView = {};
					this.dataListViewKeys = [];
				}
		}));
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getAnswersTileList(this.lectureId, this.selectedData._id , (data, error) => {
				if (error) {
					this.errorMessage = error;
				}
				if (data) {
					this.dataList = data.results;
					this.loadDataView(data.results);
				}else{
					this.dataList = [];
					this.dataListView = {};
					this.dataListViewKeys = [];
				}
				this.loading = false;
			});
		}
	}
	loadDataView(dataList) {
		let dataListView = {};
		for(let itm of dataList){
			if(!dataListView.hasOwnProperty(itm['question'])){
				dataListView[itm['question']] = [];
			}
			dataListView[itm['question']].push(itm);
		}
		this.dataListView = dataListView;
		this.dataListViewKeys = Object.keys(this.dataListView);
	}
}
