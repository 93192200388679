import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { RequestService } from "src/app/shared";
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService, TokBoxService, StoreService } from "../../services";
import { MatPaginator } from "@angular/material";
import { BehaviorSubject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss']
})

export class AgendaComponent implements OnInit, OnDestroy {

    rooms: any = [];
    sessions: any = [];
    loading: boolean = false;
    pageNumber: number = 1;
    pageSize: number = 10;
    paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private subscriptions: Subscription[] = [];
    storedFilters = undefined;
    selectedRoomId: string = undefined;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private requestService: RequestService,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private opentokService: TokBoxService,
        private store: StoreService) {
    }

    ngOnInit() {
        const paginatorSubscriptions = this.paginator.page.pipe(
            tap(() => {
                this.getTableVariables();
                this.selectRoom(this.selectedRoomId);
            })
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        this.subscriptions.push(this.opentokService.isOpenAgenda.subscribe(data => {
            if (data) {
              this.loadData();
            }else{
              this.rooms = [];
              this.sessions = [];
            }
          })
        );
    }
    loadData() {
        let filters = {
            "$and": [
                { 'organizationId._id': { '$eq': this.requestService.orgId } },
                { 'inagenda': { '$eq': true } }
            ]
        };
        this.requestService.getDataList('room', {
            fieldKeys: ['_id', 'name'], orderDir: 'asc', orderBy: 'name', filter: filters
        }, (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
            }
            if (data) {
                this.rooms = data.results;
                if (data.results) {
                  if(data.results.length > 0){
                      this.selectedRoomId = data.results[0]._id;
                      this.selectRoom(this.selectedRoomId);
                  }
                }
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        this.rooms = [];
        this.sessions = [];
    }

    selectRoom(roomId: string) {
        this.loading = true;
        this.selectedRoomId = roomId;
        let filters = {
            "$and": [{ "room._id": { "$eq": roomId } }]
        };
        let filterObj = { page: this.pageNumber, perpage: this.pageSize, orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt'], filter: filters };
        this.updateFilterStored(filterObj);
        this.requestService.getDataList('session', filterObj, (data, error) => {
            if (error) {
                this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
            }
            if (data) {
                this.sessions = data.results;
                if (data.pagination) {
                    this.paginatorTotal.next(data.pagination.total);
                }
            }
            this.loading = false;
        });
    }

    hideDialog() {
        this.opentokService.isOpenAgenda.next(false);
    }

    getTableVariables() {
        this.pageNumber = this.paginator.pageIndex + 1;
        this.pageSize = this.paginator.pageSize;
    }
    selectSession(roomId, sessionId) {
      window.location.href = '/#/rooms/' + roomId + '/sessions/' + sessionId;
      setTimeout(()=>{
        window.location.reload();
      }, 100);
    }

    setFilterStored() {
        let keyData = 'book';
        this.storedFilters = this.store.get('storedFilters');
        if (this.storedFilters) {
            if (this.storedFilters.hasOwnProperty(keyData)) {
                // this.searchVal = this.storedFilters[keyData].term;
                this.pageNumber = this.storedFilters[keyData].page;
                this.pageSize = this.storedFilters[keyData].perpage;
            }
        }
    }

    updateFilterStored(storedFilters) {
        let keyData = 'book';
        this.storedFilters = {};
        this.storedFilters[keyData] = storedFilters;
        this.store.set('storedFilters', this.storedFilters);
    }
}
