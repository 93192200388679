import { Injectable, ElementRef, Renderer2, NgZone } from '@angular/core';

import * as OT from '@opentok/client';
import { environment } from '../../../environments/environment';
import { LayoutUtilsService } from './utils/layout-utils.service';

import { BehaviorSubject, timer } from 'rxjs';
import { RequestService } from './request.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmEntityDialogComponent } from '../components/modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { MatDialog } from '@angular/material';

@Injectable()
export class TokBoxService {

  _userCapabilitiesMap: BehaviorSubject<Map<string, object>> = new BehaviorSubject<Map<string, object>>(undefined);
  sortedAttendeeMap: BehaviorSubject<Map<string, object>> = new BehaviorSubject<Map<string, object>>(undefined);
  globalSession: OT.Session;
  zIndexDraggable: BehaviorSubject<number> = new BehaviorSubject<number>(2);
  // userMap = new Map();
  userListJoinedGlobally: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isOpenGlobalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isOpenGlobalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isOpenAgenda: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isOpenAgenda: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  chatMessages: object[] = [];
  unreadChats: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  chatPrivatelyWith: any = undefined;
  _dndChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isTextChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isVideoChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  userFlags: any = {};
  _videoCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _videoCallResponse: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _hideDrawer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  // _hideCallActions: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  chatPrivatelyWithSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _playRingTone: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _answerCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _declineCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  onGoingCall: string = undefined;
  userJoinedSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  unmuteDialog: any = undefined;
  connectionHealth: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  videoChatInvite: boolean = false;
  lastChatMessage: any = { message: '' };
  _joinUserSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private layoutService: LayoutUtilsService, private requestService: RequestService, private dialog: MatDialog, private translate: TranslateService, private zone: NgZone) { }

  getOT() {
    return OT;
  }

  initSession(sessionId: string) {
    //session is a room
    //console.log(sessionId)
    if (environment.tokBox.apiKey) {
      let session = this.getOT().initSession(environment.tokBox.apiKey, sessionId);
      return Promise.resolve(session);
    }
  }

  connect(token: string, session: OT.Session) {
    //console.log(token);
    return new Promise((resolve, reject) => {
      session.connect(token, (error) => {
        if (error) {
          this.handleError(error);
          //reject(error);
        } else {
          //console.log('connected');
          //console.log(session);
          resolve(session);
        }
      });
    });
  }

  public handleError(errorCode: Object) {
    //console.log(errorCode);
    switch (errorCode['name']) {
      case 'OT_AUTHENTICATION_ERROR':
        if (errorCode['code'] == 1004) {
          this.layoutService.showNotificationSnack('Authentication error. Refresh the page.', 'Dismiss');
        }
        else {
          this.layoutService.showNotificationSnack('Authentication error.', 'Dismiss');
        }
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Unable to share screen.', 'Dismiss');
        break;
      case 'OT_CREATE_PEER_CONNECTION_FAILED':
        this.layoutService.showNotificationSnack('Client unable to join session.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Screen sharing is not supported.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED':
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED':
        this.layoutService.showNotificationSnack('Screen sharing requires a type extension, but it is not installed.', 'Dismiss');
        break;
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        this.layoutService.showNotificationSnack('Allow access and try publishing again.', 'Dismiss');
        break;
      case 'OT_NO_DEVICES_FOUND':
        this.layoutService.showNotificationSnack('You do not have a microphone attached to your computer.', 'Dismiss');
        break;
      case 'OT_NOT_CONNECTED':
        this.layoutService.showNotificationSnack('Session is not connected yet.', 'Dismiss');
        break;
      case 'OT_SOCKET_CLOSE_TIMEOUT':
      case 'OT_UNEXPECTED_SERVER_RESPONSE':
        this.layoutService.showNotificationSnack('No connection. Reloading the page.', 'Dismiss');
        setTimeout(() => window.location.reload(), 5000);
        break;
      case 'OT_HARDWARE_UNAVAILABLE':
        this.layoutService.showNotificationSnack('Audio/Video hardware is not available.', 'Dismiss');
        break;
      case 'OT_STREAM_DESTROYED':
        break;
      default:
        this.handleErrorByCode(errorCode);
        break;
    }
  }

  public handleErrorByCode(errorCode: Object) {
    switch (errorCode['code']) {
      case '1013':
        this.layoutService.showNotificationSnack('Blocked by firewall', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error, Token is invalid. ', 'Dismiss');

        break;
      case '1005':
        this.layoutService.showNotificationSnack('Authentication error, Session is invalid.', 'Dismiss');
        break;
      case '1006':
        this.layoutService.showNotificationSnack('Connection failed, check if you have internet connection.', 'Dismiss');
        break;
      case '1010':
        this.layoutService.showNotificationSnack('Cannot publish: the client is not connected to the session.', 'Dismiss');
        break;
      case '1500':
        this.layoutService.showNotificationSnack('Unable to Publish. The token does not have the role set to publish or moderator.', 'Dismiss');
        break;
      case '1501':
        this.layoutService.showNotificationSnack('Slow internet connection.', 'Dismiss');
        break;
      case '1510':
        this.layoutService.showNotificationSnack('Unable to send message.', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error. Check token expiry.', 'Dismiss');
        break;
      default:
        // this.layoutService.showNotificationSnack('Slow connection.', 'Dismiss');
        break;
    }
  }

  public sendText(message: string, session: OT.Session) {
    if (message && message.trim() != '') {
      session.signal(
        {
          data: message,
          type: 'textMessage'
        },
        function (error) {
          if (error) {
            //console.log("signal error ("
            // + error.name
            //   + "): " + error.message);
          } else {
            //message = '';
            //console.log("signal sent.");
          }
        }
      );
    }
  }

  public hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  public intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  public dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public sendSignal(type: string, message: string, currentSession: OT.Session, recepientConnection?: OT.Connection) {
    if (message && message.trim() != '') {
      let signalData;
      if (recepientConnection) {
        signalData = {
          to: recepientConnection,
          data: message,
          type: type
        }
      }
      else {
        signalData = {
          data: message,
          type: type
        }
      }
      return new Promise((resolve, reject) => currentSession.signal(signalData, (error) => {
        if (error) {
          // this.layoutService.showNotificationSnack('No connection. Reloading the page.', 'Dismiss');
          // setTimeout(() => this.sendSignal(type, message, currentSession, recepientConnection), 5000);
          //console.log("signal error (" + error.name + "): " + error.message);
          reject();
        } else {
          //console.log("signal sent." + type + ':' + message, recepientConnection);
          resolve(true);
        }
      }));
    }
  }


  public unPublishScreenAudioAndCamera(session: OT.Session, screenPublisher: OT.Publisher, mediaPublisher?: OT.Publisher) {
    if (session) {
      if (screenPublisher) {
        session.unpublish(screenPublisher);
        // screenPublisher.publishVideo(false);
        // screenPublisher.off();
      }
      if (mediaPublisher) {
        session.unpublish(mediaPublisher);
        // audioPublisher.publishAudio(false);
        // audioPublisher.off();
      }
      // if (cameraPublisher) {
      //   session.unpublish(cameraPublisher);
      //   // cameraPublisher.publishVideo(false);
      //   // audioPublisher.off();
      // }
    }
  }

  public unPublishPublisher(session: OT.Session, publisher: OT.Publisher): any {
    if (session) {
      if (publisher) {
        session.unpublish(publisher);
      }
    }
    return null;
  }

  public disconnectSession(session: OT.Session) {
    try {
      if (session) {
        session.off();
        session.disconnect();
      }
    }
    catch (e) {

    }
  }

  public unSubscribeScreenAudioAndCamera(subscribers: Array<OT.Subscriber>, session: OT.Session) {
    if (session) {
      for (let streamId in subscribers) {
        //console.log('unsubscribing now', streamId, subscribers[streamId])
        session.unsubscribe(subscribers[streamId]);
        subscribers[streamId].off();
      }
    }
  }

  public unSubscribe(subscriber: OT.Subscriber, session: OT.Session) {
    try {
      if (subscriber && subscriber.stream) {
        session.unsubscribe(subscriber);
        subscriber.off();
      }
    }
    catch (e) {
      // console.log('while unsubscribing', subscriber) 
    }
  }

  speakerDetection(subscriber, startTalking, stopTalking) {
    let activity = null;
    subscriber.on('audioLevelUpdated', (event) => {
      let now = Date.now();
      if (event.audioLevel > 0.1) {
        // console.log('event.audioLevel', event.audioLevel)
        if (!activity) {
          activity = { timestamp: now, talking: false };
        } else if (activity.talking) {
          activity.timestamp = now;
        } else if (now - activity.timestamp > 2000) {
          // detected audio activity for more than 2s
          // for the first time.
          activity.talking = true;
          if (typeof (startTalking) === 'function') {
            startTalking(event);
          }
        }
      } else if (activity && now - activity.timestamp > 3000) {
        // detected low audio activity for more than 3s
        if (activity.talking) {
          if (typeof (stopTalking) === 'function') {
            stopTalking(event);
          }
        }
        activity = null;
      }
    });
  };

  set userCapabilitiesMap(map: any | undefined) {
    //console.log('map', map);
    this._userCapabilitiesMap.next(map);
  }

  get userCapabilitiesMap(): any | undefined {
    //console.log('mthis._userCapabilitiesMapap', this._userCapabilitiesMap);
    return this._userCapabilitiesMap;
  }

  reOrderMap(studentRequests: Map<any, any>, data: object): Map<any, any> {
    //reorder studentRequests
    let tempObj = studentRequests.get(data['userId']);
    studentRequests.delete(data['userId']);
    const arr = Array.from(studentRequests);
    arr.splice(1, 0, [data['userId'], tempObj]);
    studentRequests.clear();
    arr.forEach(([k, v]) => studentRequests.set(k, v));
    this.sortedAttendeeMap.next(studentRequests);
    return studentRequests;
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  deleteEntryFromMap(map: Map<any, any>, toDelete: string, renderer: Renderer2) {
    this.removeOverlayName(toDelete, renderer);

    Object.keys(map).forEach((key) => {
      if (key == toDelete)
        delete map[key];
    });
  }

  removeOverlayName(toDelete: string, renderer: Renderer2) {
    //remove overlay name from dom
    // let element = document.querySelector('[data-id="name-' + toDelete + '-corner"]');
    // if (element)
    //   renderer.removeChild(document.body, element);
    // element = document.querySelector('[data-id="name-' + toDelete + '"]');
    // if (element)
    //   renderer.removeChild(document.body, element);
    document.querySelectorAll('[data-id*="' + toDelete + '"').forEach(element => {
      renderer.removeChild(document.body, element);
    });
  }

  switchDisplay(user: object, element: ElementRef, renderer: Renderer2) {
    // let userImage = element.nativeElement.querySelectorAll('[data-id="default-image"]')[0];
    // if (userImage)
    //   userImage.setAttribute('style', 'display:none');
    //console.log('user camera', user['streams']['media'], element)
    element.nativeElement.querySelectorAll('[data-type="media"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });
    // element.nativeElement.querySelectorAll('[data-type="audio"]').forEach(element => {
    //   renderer.setStyle(element, 'display', 'none');
    // });
    element.nativeElement.querySelectorAll('[data-id^="name-"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });

    // console.log('in switch', element, user['streams']['camera'], user['streams']['audio'], user['streams']['camera']['streamId'], user['streams']['audio']['streamId'], element.nativeElement.querySelectorAll('[data-id="' + user['streams']['camera']['streamId'] + '"]')[0], element.nativeElement.querySelectorAll('[data-id="' + user['streams']['audio']['streamId'] + '"]')[0])
    if (user['streams']['media']) {
      let elem = element.nativeElement.querySelector('[data-id="' + user['streams']['media']['streamId'] + '"]');
      if (elem)
        renderer.removeStyle(elem, 'display');
      //console.log('has video', user['streams']['media'].hasVideo)
      if (user['streams']['media'].hasVideo) {
        elem = element.nativeElement.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '-corner"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
      else {
        elem = element.nativeElement.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
      // console.log('user audio', user['streams']['audio'])
      // if (user['streams']['audio']) {
      //   element.nativeElement.querySelectorAll('[data-id="' + user['streams']['audio']['streamId'] + '"]')[0].setAttribute('style', 'display:none');
      // }
    }
    // else if (user['streams']['audio']) {
    //   console.log('user audio id', user['streams']['audio']['streamId'])
    //   let elem = element.nativeElement.querySelectorAll('[data-id="' + user['streams']['audio']['streamId'] + '"]')[0];
    //   if (elem)
    //     renderer.removeStyle(elem, 'display');
    //   elem = element.nativeElement.querySelector('[data-id="name-' + user['streams']['audio']['streamId'] + '"]');
    //   if (elem)
    //     renderer.removeStyle(elem, 'display');
    // }
    // else {
    //   if (userImage)
    //     userImage.removeAttribute('style');
    // }
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  generateRandomId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  streamChanged(event: any, renderer: Renderer2, attendeeMap: any) {
    let stream: OT.Stream = event.stream;
    let data = JSON.parse(event.stream.connection.data);
    let userObj = attendeeMap.get(data['userId']);

    if (event.changedProperty == 'hasVideo') {
      if (event.newValue) {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }
      else {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }

      // userObj['audioOnOff'] = event.stream.hasAudio;
      // userObj['sharingVideo'] = event.stream.hasVideo;

    }
    else if (event.changedProperty == 'hasAudio') {
      userObj['audioOnOff'] = event.stream.hasAudio;
    }

    attendeeMap.set(data['userId'], userObj);
    this.userCapabilitiesMap = attendeeMap;
  }

  subscribeToMedia(stream: OT.Stream, container: any, session: OT.Session, renderer: Renderer2, retryInSec: number, cameraResolution, insertMode: any, callBack?: () => void, subscribetoCamera?: boolean, firstUser?: boolean): OT.Subscriber {
    //console.log('camera stream', stream);
    let data = JSON.parse(stream.connection.data);
    // renderer.setProperty(container.nativeElement, 'innerHTML', '');

    let element = renderer.createElement('div');
    renderer.setStyle(element, 'height', '100%');
    renderer.setStyle(element, 'width', '100%');
    renderer.setAttribute(element, 'data-type', 'media');
    renderer.setAttribute(element, 'data-id', stream.streamId);
    //console.log('first user', firstUser)
    if (!firstUser) {
      renderer.setStyle(element, 'display', 'none');
    }

    let overlayElement = renderer.createElement('div');
    renderer.setAttribute(overlayElement, 'class', 'name-overlay-container');
    renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId);
    let nameElement = renderer.createElement('div');
    renderer.setProperty(nameElement, 'innerHTML', data['name']);
    if (stream.hasVideo || !firstUser) {
      renderer.setStyle(overlayElement, 'display', 'none');
    }
    overlayElement.append(nameElement);
    container.prepend(overlayElement);

    overlayElement = renderer.createElement('div');
    renderer.setAttribute(overlayElement, 'class', 'name-overlay-container-corner');
    renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId + '-corner');
    nameElement = renderer.createElement('div');
    renderer.setProperty(nameElement, 'innerHTML', data['name']);
    if (!stream.hasVideo || !firstUser) {
      renderer.setStyle(overlayElement, 'display', 'none');
    }
    overlayElement.append(nameElement);
    container.prepend(overlayElement);

    container.append(element);

    let userImage = '';
    if (data.hasOwnProperty('userImage') && data['userImage']) {
      userImage = data['userImage'];
    }
    else {
      userImage = this.requestService.serverHostUrl + '/assets/images/defaultattendee.png';
    }

    let timer;
    let subscriber: OT.Subscriber;
    subscriber = session.subscribe(stream, element, { width: '100%', height: '100%', insertMode: insertMode, style: { backgroundImageURI: userImage, nameDisplayMode: 'off', buttonDisplayMode: 'off', audioLevelDisplayMode: 'off', audioBlockedDisplayMode: 'off', videoDisabledDisplayMode: 'off' }, subscribeToAudio: true, subscribeToVideo: subscribetoCamera }, (error) => {
      //preferredResolution: this.cameraResolution
      if (error) {
        renderer.removeChild(document.body, overlayElement);
        renderer.removeChild(document.body, nameElement);
        this.handleError(error);
        if (error['code'] == 1501) {
          timer = setTimeout(() => this.subscribeToMedia(stream, container, session, renderer, retryInSec, cameraResolution, insertMode), retryInSec * 1000);
        }
      }
      else {
        // let audioCtx = new AudioContext();
        // audioCtx.resume();
        clearTimeout(timer);
        if (callBack) {
          callBack();
        }
      }
    });
    // subscriber.restrictFrameRate(true);
    subscriber.on("videoDisabled", (event) => {
      console.log('video is disabled', event);
      // this.videoDisabled();
    });
    subscriber.on("videoDisableWarning", (event) => {
      console.log('video warning');
      this.connectionHealth.next(false);
      // this.videoWarning(subscriber, { width: 320, height: 240 });
    });
    subscriber.on("videoDisableWarningLifted", (event) => {
      console.log("video warning lifted");
      this.connectionHealth.next(true);
    });
    subscriber.on("videoEnabled", (event) => {
      console.log("video enabled");
    });
    // subscriber.on("audioBlocked", (event) => {
    //   console.log('audio blocked due to autoplay policy')
    // });
    // subscriber.on("audioUnBlocked", (event) => {
    //   console.log('audio is unblocked')
    // });
    return subscriber;
  }

  showSimpleDialog(data: string) {
    if (typeof data == 'string')
      this.showNotificationDialog(data, '');
    else {
      let link = '';
      if (data['link']) {
        link = '/#/rooms/' + data['link']['roomId'] + '/sessions/' + data['link']['sessionId'];
      }
      this.showNotificationDialog(data['message'], link);
    }
  }

  showNotificationDialog(message: string, link: string) {
    this.zone.run(() => {
      let dataObj = {
        title: 'Announcement',
        data: '',
        description: message,
        cancelbtn: this.translate.instant('Close')
      };
      if (link) {
        dataObj['confirmbtn'] = this.translate.instant('Go');
      }
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        disableClose: true,
        data: dataObj,
        width: '40vw'
      });
      if (link)
        dialogRef.afterClosed().subscribe(result => {
          //console.log('result from dialog', result);
          if (result !== undefined) {
            window.location.href = link;
            setTimeout(() => {
              window.location.reload();
            }, 50);
          }
        });
    });
  }

  // dragged(bool: boolean, containerDiv, renderer: Renderer2) {
  //   console.log('parentDiv', containerDiv)
  //   if (containerDiv) {
  //     if (bool) {
  //       // renderer.setStyle(containerDiv, 'z-index', 10);
  //     }
  //     else {
  //       // renderer.setStyle(containerDiv, 'z-index', 0);
  //     }
  //   }
  // }

  switchToNextUser(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && (attendee['role'] === 'attendee' || attendee['role'] === 'anonymous')) {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          this.switchDisplay(userTurnOn, container, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  switchToNextGuest(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && attendee['role'] === 'guest') {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          this.switchDisplay(userTurnOn, container, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  extractYoutubeVideoId(videoUrl: string): string {
    let id;
    if (videoUrl) {
      let url = videoUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_\-]/i);
        id = id[0];
      }
      else {
        id = url;
      }
    }
    return id;
  }

  extractVimeoVideoId(videoUrl: string): string {
    let regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

    if (videoUrl) {
      let match = videoUrl.match(regExp);

      if (match.length >= 2) {
        return match[1];
      }
      else {
        return null;
      }
    }
    return null;
  }

  // onPlayerStateChange(event, player) {
  //   // console.log(event)
  //   switch (event.data) {
  //     case window['YT'].PlayerState.PLAYING:
  //       if (this.cleanTime(player) == 0) {
  //         console.log('started ' + this.cleanTime(player));
  //       } else {
  //         console.log('playing ' + this.cleanTime(player))
  //       };
  //       break;
  //     case window['YT'].PlayerState.PAUSED:
  //       if (player.getDuration() - player.getCurrentTime() != 0) {
  //         console.log('paused' + ' @ ' + this.cleanTime(player));
  //       };
  //       break;
  //     case window['YT'].PlayerState.ENDED:
  //       console.log('ended ');
  //       break;
  //   };
  // };

  cleanTime(player) {
    if (player)
      return Math.round(player.getCurrentTime())
    return null;
  }

  time_convert(num) {
    let sec_num = parseInt(num, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    let secondsSt, minutesSt, hoursSt;
    if (hours < 10) {
      hoursSt = "0" + hours;
    }
    else
      hoursSt = hours;
    if (minutes < 10) {
      minutesSt = "0" + minutes;
    }
    else
      minutesSt = minutes;
    if (seconds < 10) {
      secondsSt = "0" + seconds;
    }
    else
      secondsSt = seconds;
    // debugger;
    return hoursSt + 'h' + minutesSt + 'm' + secondsSt + 's';
  }

  initYoutube() {
    let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/player_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  initVimeo() {
    let tag = document.createElement('script');
    tag.src = 'https://player.vimeo.com/api/player.js';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  videoWarning(subscriber: OT.Subscriber, preferredResolution) {
    // subscriber.setPreferredResolution(preferredResolution);
    this.layoutService.showNotificationSnack(this.translate.instant('Low internet connection detected - the video may be disabled'), this.translate.instant('Dismiss'));
  }

  videoDisabled() {
    this.layoutService.showNotificationSnack(this.translate.instant('The video was disabled due to a bad internet connection'), this.translate.instant('Dismiss'));
  }

  // videoWarningLifted(){
  //   this.layoutService.showNotificationSnack(this.translate.instant('Due to low internet bandwidth, the video will be disabled'), this.translate.instant('Dismiss'));
  // }
  showUnBlockAudioDialog() {
    if (!this.unmuteDialog) {
      this.zone.run(() => {
        let alertSetting = {};
        alertSetting['icon'] = 'campaign';
        alertSetting['overlayClickToClose'] = false;
        alertSetting['showCloseButton'] = false;
        alertSetting['confirmText'] = 'Unmute';
        alertSetting['buttonColor'] = 'primary';

        this.unmuteDialog = this.layoutService.alertActionElement('', this.translate.instant("Unmute Speaker Audio"), alertSetting);
        this.unmuteDialog.afterClosed().subscribe(res => {
          if (res) {
            OT.unblockAudio();
            this.unmuteDialog = undefined;
          }
        });
      });
    }
  }

  getCounter(tick) {
    return timer(0, tick);
  }

  getVideoCallCounter(tick) {
    return timer(0, tick);
  }
}
