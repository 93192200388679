import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, ViewEncapsulation, ChangeDetectorRef, Renderer2, Pipe, PipeTransform } from "@angular/core";
import { TokBoxService, RequestService, ConfirmEntityDialogComponent } from "src/app/shared";
import { TranslateService } from '@ngx-translate/core';
import { Subscription, BehaviorSubject } from "rxjs";
import { MatDialog } from "@angular/material";
import { SpeakerCameraComponent } from "src/app/pages/rooms/components/session-page/camera-play/camera-play.component";
import { LoaderService, LayoutUtilsService } from "../../services";

@Component({
    selector: 'app-global-chat',
    templateUrl: './global-chat.component.html',
    styleUrls: ['./global-chat.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GlobalChatComponent implements OnInit, OnDestroy {

    chattingWithName: string = undefined;
    private subscriptions: Subscription[] = [];
    selectedChat: any = undefined;
    filterByText: string = '';
    _filteredList: [] = [];
    _filteredActiveList: [] = [];
    usersJoined: any = [];
    mediaFlags: any = undefined;
    disableIcon: boolean = false;
    speakerCameraPublisher: OT.Publisher;
    inviteeCameraSubscriber: OT.Subscriber;
    intervalInSecondsConnectionDisconnected: number = 5;
    retrySubscribingToMedia: number = 5;
    userImg: string = '';
    streamHasAudio: boolean = false;
    streamHasVideo: boolean = false;
    userSession: OT.Session;
    _selectedUser: any = undefined;
    userMap = new Map();
    caller: any = undefined;
    // countDownTextMin: number = undefined;
    // countDownTextSec: number = undefined;
    counterSubscription: Subscription = undefined;
    counter: number;
    tick: number = 1000;
    stream: OT.Stream;
    loading: boolean = false;
    streamedFirstTime: boolean = false;

    @ViewChild('message') message: ElementRef;
    @ViewChild('chat') chat: ElementRef;
    @ViewChild('cameraOfInviter_component') cameraOfInviter_component: SpeakerCameraComponent;
    @ViewChild('inviteeCamera') inviteeCamera: ElementRef;

    @Input() hideToggle: boolean = undefined;
    @Input()
    set chatPrivatelyWith(chatPrivatelyWith: any) {
        if (chatPrivatelyWith) {
            this.selectAttendee(chatPrivatelyWith);
            // this.addandSetChatWith(chatPrivatelyWith);
        }
    }
    get chatPrivatelyWith(): any {
        return this.opentokService.chatPrivatelyWith;
    }
    @Input()
    set selectedUser(selectedUser: any) {
        if (selectedUser) {
            this._selectedUser = selectedUser;
            if (selectedUser['pictureLink'])
                this.userImg = selectedUser['pictureLink'];
            else
                this.userImg = this.requestService.serverHostUrl + '/assets/images/defaultpresenter.png';
        }
    }
    get selectedUser(): any {
        return this._selectedUser;
    }

    constructor(public opentokService: TokBoxService,
        private translate: TranslateService,
        private requestService: RequestService,
        private changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2,
        public dialog: MatDialog,
        private loadService: LoaderService,
        private layoutUtilsService: LayoutUtilsService) {
    }

    ngOnInit() {
        this.subscriptions.push(this.opentokService.userListJoinedGlobally.subscribe((data) => {
            if (data) {
                if (data.joined) {
                    let idx = this.usersJoined.findIndex(i => i.user.userId == data.user['userId']);
                    if (idx == -1)
                        this.usersJoined.push(data);
                    //console.log('joined', data);
                }
                else {
                    this.usersJoined = this.usersJoined.filter(i => i.user['userId'] != data.user['userId']);
                    if (this.selectedChat && data.user['userId'] == this.selectedChat['userId']) {
                        this.switchToPublicChat();
                    }
                    if (this.caller && this.caller.hasOwnProperty('userId') && data.user['userId'] == this.caller.userId) {
                        this.hangup();
                    }
                }
                // debugger;
                this.filterList();
            }
        }));

        this.subscriptions.push(this.opentokService._videoCall.subscribe((event) => {
            if (event != undefined) {
                // this.opentokService._videoCallTopNav.next(event);
                let now = new Date();
                let sender = JSON.parse(event['from']['data']);
                let idx = this.usersJoined.findIndex(i => i.user.userId == sender['userId']);
                if (idx != -1) {
                    if (event['data'] == '1') {
                        if (!this.opentokService.onGoingCall) { }
                        this.usersJoined[idx].flags.videoCallRequested = 3;
                        if (this.opentokService.onGoingCall && this.opentokService.onGoingCall != sender['userId'])
                            this.opentokService.sendSignal('videoChatInviteResponse', '-1', this.opentokService.globalSession, this.usersJoined[idx].user.connection);
                    }
                    else if (event['data'] == '-1') {
                        this.usersJoined[idx].flags.videoCallRequested = 0;
                        let color = 'rgb(193, 70, 42)';
                        if (this.opentokService.chatMessages[sender['userId']]) {
                            this.opentokService.chatMessages[<string>sender['userId']].unshift({ message: 'You missed a video call from ' + sender['name'] + ' at ' + this.opentokService.formatAMPM(now), color: color, sender: sender['name'], missedCall: true });
                        }
                        else {
                            this.opentokService.chatMessages[sender['userId']] = [{ message: 'You missed a video call from ' + sender['name'] + ' at ' + this.opentokService.formatAMPM(now), color: color, sender: sender['name'], missedCall: true }];
                        }
                        //console.log('missed call', this.opentokService.chatMessages)
                    }
                    else {
                        this.usersJoined[idx].flags.videoCallRequested = 0;
                    }
                    this.filterList();
                }

                if (event['data'] == '1' && !this.opentokService.onGoingCall) {
                    this.opentokService.videoChatInvite = true;
                    let sender = JSON.parse(event['from']['data']);
                    // this.opentokService.onGoingCall = sender['userId'];
                    this.opentokService.lastChatMessage = { message: sender['nickname'] + ' is inviting you to a 2 min video chat.', id: sender['userId'], connection: sender['connection'] };

                    this.opentokService._hideDrawer.next(false);
                    this.opentokService.unreadChats.next({ 'unread': { id: sender['userId'], sender: sender['nickname'], message: ' is inviting you to a 2 min video chat.' } });
                }
                else {
                    this.opentokService._hideDrawer.next(true);
                    // this.opentokService.onGoingCall = undefined;
                    this.opentokService.videoChatInvite = false;
                    this.opentokService.lastChatMessage = { message: '' };
                    // this.lastChatMessageId = undefined;
                }
            }
        }));

        this.subscriptions.push(this.opentokService._joinUserSession.subscribe((hostId) => {
            if (hostId)
                this.startSession(hostId);
        }));

        // this.subscriptions.push(this.opentokService._hideCallActions.subscribe((data) => {
        //     if (data) {
        //         if (data.hide) {
        //             let idx = this.usersJoined.findIndex(i => i.user.userId == data.id);
        //             this.usersJoined[idx].flags.videoCallRequested = 0;
        //             this.detectChanges();
        //         }
        //     }
        // }));

        this.subscriptions.push(this.opentokService._videoCallResponse.subscribe((event) => {
            if (event != undefined) {
                let sender = JSON.parse(event['from']['data']);
                let idx = this.usersJoined.findIndex(i => i.user.userId == sender['userId']);
                this.opentokService._playRingTone.next(false);
                if (idx != -1) {
                    if (event['data'] == '0') {
                        this.usersJoined[idx].flags.videoCallRequested = 0;
                    }
                    else if (event['data'] == '1') {
                        this.usersJoined[idx].flags.videoCallRequested = 2;
                        this.startSession(this.selectedUser['_id'], event['from']);
                        // const dialogRef = this.layoutUtilsService.alertElement('', 'Video call starting in few seconds. This dialog will automatically close.');
                    }
                    else if (event['data'] == '-1') {
                        this.usersJoined[idx].flags.videoCallRequested = 0;
                        this.layoutUtilsService.showNotificationSnack(this.translate.instant('The person you are calling is on another call.'), this.translate.instant('Dismiss'));
                    }
                    this.detectChanges();
                }
            }
        }));

        this.subscriptions.push(this.opentokService._declineCall.subscribe((data) => {
            if (data) {
                this.declineCall(data);
            }
        }));

        this.subscriptions.push(this.opentokService._answerCall.subscribe((data) => {
            if (data) {
                this.answerCall(data);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
        this.chattingWithName = undefined;
        this.selectedChat = undefined;
        this.filterByText = '';
        this._filteredList = [];
        this._filteredActiveList = [];
        this.usersJoined = [];
        this.mediaFlags = undefined;
        this.disableIcon = false;
        this.speakerCameraPublisher = undefined;
        this.inviteeCameraSubscriber = undefined;
        this.userImg = '';
        this.streamHasAudio = false;
        this.streamHasVideo = false;
        this.userSession = undefined;
        this._selectedUser = undefined;
        this.userMap = new Map();
        this.caller = undefined;
        // this.countDownTextMin = undefined;
        // this.countDownTextSec = undefined;
        if (this.counterSubscription)
            this.counterSubscription.unsubscribe();
        this.stream = undefined;
        this.opentokService.chatMessages = [];
        this.opentokService.onGoingCall = undefined;
        this.opentokService.userFlags = [];
        this.opentokService.chatPrivatelyWith = undefined;
    }

    addandSetChatWith(chatPrivatelyWith: any) {
        if (this.chatPrivatelyWith) {
            // this.flags.next({ id: this.chatPrivatelyWith, flag: 'selected', value: false });
            if (this.opentokService.userFlags.hasOwnProperty(this.chatPrivatelyWith)) {
                this.opentokService.userFlags[this.chatPrivatelyWith].selected = false;
                let idx = this.usersJoined.findIndex(i => i.user.userId == this.chatPrivatelyWith);
                if (idx != -1) {
                    this.usersJoined[idx].flags.selected = false;
                }
            }
        }

        this.opentokService.chatPrivatelyWith = chatPrivatelyWith.id;
        let idx = this.usersJoined.findIndex(i => i.user['userId'] == this.chatPrivatelyWith);
        if (idx != -1)
            this.selectedChat = this.usersJoined[idx].user;

        if (this.selectedChat) {
            // this.flags.next({ id: this.chatPrivatelyWith, flag: 'hide', value: false });
            this.opentokService.userFlags[this.chatPrivatelyWith].hide = false;
            let idx = this.usersJoined.findIndex(i => i.user.userId == this.chatPrivatelyWith);
            if (idx != -1) {
                this.usersJoined[idx].flags.hide = false;
            }
            this.chattingWithName = this.selectedChat['name'];
            if (!this.opentokService.chatMessages[this.chatPrivatelyWith])
                this.opentokService.chatMessages[this.chatPrivatelyWith] = [];

            this.opentokService.unreadChats.next(null);
        }

        this.filterList();
    }

    chatWithAttendee(attendeeId: any) {
        if (attendeeId) {
            // this.flags.next({ id: this.chatPrivatelyWith, flag: 'unread', value: false });
            // this.flags.next({ id: this.chatPrivatelyWith, flag: 'selected', value: true });
            // this.flags.next({ id: this.chatPrivatelyWith, flag: 'hide', value: false });

            let flags = this.opentokService.userFlags[attendeeId];
            if (flags) {
                flags.unread = false;
                flags.selected = true;
                flags.hide = false;
                this.opentokService.userFlags[attendeeId] = flags;
            }

            let idx = this.usersJoined.findIndex(i => i.user.userId == attendeeId);
            if (idx != -1) {
                this.usersJoined[idx].flags.selected = true;
                this.usersJoined[idx].flags.unread = false;
                this.usersJoined[idx].flags.hide = false;
            }
            this.opentokService.unreadChats.next(null);
        }
    }

    sendMessage() {
        this.opentokService.sendText(this.message.nativeElement.value, this.opentokService.globalSession);
        this.message.nativeElement.value = '';
    }

    sendPrivateMessage() {
        if (this.opentokService.userFlags.hasOwnProperty(this.opentokService.chatPrivatelyWith) && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].joined && !this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].dnd && this.opentokService.userFlags[this.opentokService.chatPrivatelyWith].showTextChat && this.message.nativeElement.value.trim() != '') {
            let color = 'rgb(193, 70, 42)';
            this.opentokService.sendSignal('privateTextMessage', this.message.nativeElement.value, this.opentokService.globalSession, this.selectedChat['connection']);
            //console.log('this.chatPrivatelyWith', this.chatPrivatelyWith)

            let senderName = 'me';

            if (this.opentokService.chatMessages[this.chatPrivatelyWith]) {
                this.opentokService.chatMessages[<string>this.chatPrivatelyWith].unshift({ message: this.message.nativeElement.value, color: color, sender: senderName });
            }
            else {
                this.opentokService.chatMessages[this.chatPrivatelyWith] = [{ message: this.message.nativeElement.value, color: color, sender: senderName }];
            }
            this.message.nativeElement.value = '';
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    switchToPublicChat() {
        this.opentokService.chatPrivatelyWith = undefined;
        this.chattingWithName = undefined;

        this.usersJoined.map(i => i.flags.selected = false);
        for (let i in this.opentokService.userFlags) {
            // this.flags.next({ id: i, flag: 'selected', value: false });
            this.opentokService.userFlags[i]['selected'] = false;

        }
    }

    selectAttendee(attendee: any) {
        this.addandSetChatWith(attendee);
        this.chatWithAttendee(attendee.id);
    }

    hideChat(attendeeId) {
        if (attendeeId) {
            // this.flags.next({ id: attendeeId, flag: 'selected', value: false });
            if (this.opentokService.userFlags.hasOwnProperty(attendeeId)) {
                this.opentokService.userFlags[attendeeId].selected = false;
                this.opentokService.userFlags[attendeeId].hide = true;
            }

            let idx = this.usersJoined.findIndex(i => i.user.userId == attendeeId);
            if (idx != -1 && this.usersJoined[idx].hasOwnProperty('flags')) {
                this.usersJoined[idx].flags.selected = false;
                this.usersJoined[idx].flags.hide = true;

                if (this.usersJoined[idx].flags.videoCallRequested != 0) {
                    this.opentokService.sendSignal('videoChatInvite', '-1', this.opentokService.globalSession, this.usersJoined[idx].user.connection);
                    if (this.usersJoined[idx].flags.videoCallRequested == 2) {
                        this.hangup();
                    }
                    this.usersJoined[idx].flags.videoCallRequested = 0;
                }
            }
            this.filterList();
            this.detectChanges();
        }
    }

    hideDialog() {
        this.hideChat(this.chatPrivatelyWith);
        this.opentokService.chatPrivatelyWith = undefined;
        this.opentokService.isOpenGlobalChat.next(false);
        this.hangup();
    }

    private _filter(value: string, list: any): [] {
        const filterValue = value.toLowerCase();
        return list.filter(option => option.user['name'].toLowerCase().indexOf(filterValue) != -1);
    }

    filterList() {
        this._filteredList = this._filter(this.filterByText, this.usersJoined.filter(i => i.flags.hide == true && i.flags.showTextChat && i.user.userId != this.selectedUser['_id']));
        this._filteredActiveList = this._filter(this.filterByText, this.usersJoined.filter(i => i.flags.hide == false && i.flags.showTextChat && i.user.userId != this.selectedUser['_id']));
        //console.log('this._', this._filteredList, this._filteredActiveList, this.usersJoined)
        this.detectChanges();
    }

    inviteForVideoChat(user: any) {
        let idx = this.usersJoined.findIndex(i => i.user.userId == user.userId);
        if (idx != -1) {
            if (this.usersJoined[idx].flags.videoCallRequested == 0) {
                const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
                    disableClose: false,
                    data: {
                        title: this.translate.instant('Video Call'),
                        data: '',
                        description: this.translate.instant('Start a 2 min video call with ' + user.name + '?'),
                        cancelbtn: this.translate.instant('Cancel'),
                        confirmbtn: this.translate.instant('Proceed'),
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result !== undefined) {
                        this.opentokService.sendSignal('videoChatInvite', '1', this.opentokService.globalSession, user.connection);
                        if (this.usersJoined[idx] && this.usersJoined[idx].hasOwnProperty('flags'))
                            this.usersJoined[idx].flags.videoCallRequested = 1;
                        this.opentokService._playRingTone.next(true);
                        this.caller = user;
                    }
                    else {
                        this.opentokService.sendSignal('videoChatInvite', '0', this.opentokService.globalSession, user.connection);
                        if (this.usersJoined[idx] && this.usersJoined[idx].hasOwnProperty('flags'))
                            this.usersJoined[idx].flags.videoCallRequested = 0;
                        this.opentokService._playRingTone.next(false);
                        this.caller = undefined;
                    }
                });
            }
            else {
                this.opentokService.sendSignal('videoChatInvite', '-1', this.opentokService.globalSession, user.connection);
                if (this.usersJoined[idx] && this.usersJoined[idx].hasOwnProperty('flags'))
                    this.usersJoined[idx].flags.videoCallRequested = 0;
                this.opentokService._playRingTone.next(false);
                this.caller = undefined;
            }
            this.filterList();
        }
    }

    declineCall(user: any) {
        let idx = this.usersJoined.findIndex(i => i.user.userId == user.userId);
        if (idx != -1) {
            this.usersJoined[idx].flags.videoCallRequested = 0;
            this.opentokService.sendSignal('videoChatInviteResponse', '0', this.opentokService.globalSession, this.usersJoined[idx].user.connection);
        }
        this.opentokService._hideDrawer.next(true);
        this.detectChanges();
    }

    answerCall(user: any) {
        // debugger;
        this.caller = user;
        let idx = this.usersJoined.findIndex(i => i.user.userId == user.userId);
        if (idx != -1) {
            this.usersJoined[idx].flags.videoCallRequested = 2;
            this.opentokService.sendSignal('videoChatInviteResponse', '1', this.opentokService.globalSession, this.usersJoined[idx].user.connection);
        }
        this.opentokService._hideDrawer.next(true);
        //console.log('this.userjoins', this.usersJoined);
        // debugger;
        // this.startSession(user.userId);
        // this.shareMedia(true, true);
    }

    hangup() {
        if (this.counterSubscription) {
            this.counterSubscription.unsubscribe();
            this.counterSubscription = null;
        }
        if (this.opentokService.onGoingCall) {
            this.opentokService.onGoingCall = undefined;
            if (this.caller && this.caller.hasOwnProperty('userId')) {
                let idx = this.usersJoined.findIndex(i => i.user.userId == this.caller.userId);
                if (idx != -1 && this.usersJoined[idx].hasOwnProperty('flags'))
                    this.usersJoined[idx].flags.videoCallRequested = 0;
            }
            // if (this.stream && this.stream.hasOwnProperty('streamId'))
            //     this.opentokService.removeOverlayName(this.stream.streamId, this.renderer);
            this.stopSharing();
            this.opentokService.disconnectSession(this.userSession);
            this.caller = undefined;
            // this.userSession = undefined;
            this.inviteeCameraSubscriber = undefined;
            // clearInterval(this.countDownInterval);
            // this.countDownInterval = undefined;
            // this.countDownTextMin = undefined;
            // this.countDownTextSec = undefined;
            // this.stream = undefined;
            this.streamedFirstTime = false;
            this.inviteeCamera.nativeElement.innerHTML = '';
            this.userSession = null;
            this.inviteeCameraSubscriber = null;
            this.speakerCameraPublisher = null;
            this.counter = undefined;
            this.streamHasAudio = false;
            this.streamHasVideo = false;
            if (this.caller && this.caller.hasOwnProperty('userId') && this.opentokService.lastChatMessage == this.caller.userId)
                this.opentokService._hideDrawer.next(true);
            this.detectChanges();
        }
    }

    private stopSharing() {
        try {
            this.speakerCameraPublisher = this.opentokService.unPublishPublisher(this.userSession, this.speakerCameraPublisher);
            if (this.stream)
                this.opentokService.unSubscribe(this.inviteeCameraSubscriber, this.userSession);
        }
        catch (e) { }
    }

    private startSession(hostId: string, recepient?: any) {
        if (OT.checkSystemRequirements()) {
            this.loading = true;
            // this.loadService.display(true);
            let obj = this;
            this.requestService.getOpenTokSessionKeyForUser(hostId, (data, error) => {
                if (error) {
                    setTimeout(() => { obj.startSession(hostId, recepient); }, this.intervalInSecondsConnectionDisconnected * 1000);
                    this.layoutUtilsService.showNotificationSnack('No connection. Reconnecting...', this.translate.instant('Dismiss'));
                }
                else if (data && data.status) {
                    let sessionId = data['results']['openTokSessionId'];
                    this.requestService.getOpenTokTokenKeyForUser(this.selectedUser, hostId, this.userImg, (data, error) => {
                        if (error) {
                            this.layoutUtilsService.showNotificationSnack('Invalid token.', this.translate.instant('Dismiss'));
                        }
                        else if (data && data.status) {
                            let token = data['results']['token'];
                            this.initiateOpenTok(sessionId, token, hostId, recepient);
                        }
                        else {
                            this.layoutUtilsService.showNotificationSnack('Invalid token.', this.translate.instant('Dismiss'));
                        }
                    })
                }
            });
        }
    }

    initiateOpenTok(sessionId: string, token: string, hostId: string, recepient?: any) {
        let obj = this;
        this.opentokService.initSession(sessionId).then((session: OT.Session) => {
            this.userSession = session;
            this.userSession.on('sessionDisconnected', (event) => {
                //console.log('session disconnected')
                setTimeout(() => { obj.startSession(hostId, recepient); }, this.intervalInSecondsConnectionDisconnected * 1000);
                this.layoutUtilsService.showNotificationSnack('Reconnecting...', this.translate.instant('Dismiss'));
            });
            this.userSession.on('sessionReconnecting', (event) => {
                //console.log('session reconnecting')
            });
            this.userSession.on('sessionReconnected', (event) => {
                //console.log('session reconnected')
            });
            this.userSession.on('streamCreated', (event) => {
                //console.log('stream added parent', event.stream.videoType, event.stream);
                let data = JSON.parse(event.stream.connection.data);
                let userObj = this.userMap.get(data['userId']);
                let userStream = userObj['streams'];
                userStream['media'] = event.stream;
                this.stream = event.stream;
                this.inviteeCameraSubscriber = obj.subscribeToMedia(event.stream, this.inviteeCamera.nativeElement, true, true, 'replace', () => {
                    if (!obj.streamedFirstTime) {
                        obj.streamedFirstTime = true;

                        obj.counter = 2 * 60;
                        obj.countDown();
                    }
                    this.detectChanges();
                });
            });
            this.userSession.on('connectionCreated', (event) => {
                let userJoined = JSON.parse(event.connection.data);
                if (!obj.userMap.has(userJoined['userId'])) {
                    let user = { name: userJoined['name'], requestToPresent: false, presenting: false, connection: event.connection, requestToShareVideo: false, audioOnOff: false, streams: { 'media': '', 'screen': '' } };

                    obj.userMap.set(userJoined['userId'], user);


                }
            });
            this.userSession.on('streamPropertyChanged', (event) => {
                //console.log('propery changed', event)
                obj.opentokService.streamChanged(event, this.renderer, this.userMap);
            });
            this.userSession.on('streamDestroyed', (event) => {
                let data = JSON.parse(event.stream.connection.data);
                let userObj = this.userMap.get(data['userId']);
                let userStream = userObj['streams'];
                userStream['media'] = '';
                //console.log('user stream destroyed', event.stream)
                this.opentokService.removeOverlayName(event.stream.streamId, this.renderer);
            });
            this.userSession.on('connectionDestroyed', (event) => {
                //console.log('user left chat', event)
                let userLeft = JSON.parse(event.connection.data);
                obj.userMap.delete(userLeft['userId']);
                this.hangup();
            });
        }).then(() => {
            this.opentokService.connect(token, this.userSession).then((data) => {
                if (data) {
                    if (recepient)
                        this.opentokService.sendSignal('userSessionStarted', '1', this.opentokService.globalSession, recepient);
                    this.mediaFlags = { audio: true };
                    this.invokeShareAudio(true);
                    this.loading = false;
                    this.detectChanges();
                }
                // this.loadService.display(false);
            });
        })
            .catch((error) => {
                // this.loadService.display(false);
                this.layoutUtilsService.showNotificationSnack('Unable to connect.', 'Dismiss');
            });
    }

    shareMedia(audioOn: boolean, cameraOn: boolean) {
        let obj = this;
        this.opentokService.onGoingCall = this.selectedUser['_id'];
        this.disableIcon = true;

        this.cameraOfInviter_component.publisherCameraDiv.nativeElement.innerHTML = '';
        let element = this.renderer.createElement('div');
        this.renderer.setStyle(element, 'height', '100%');
        this.renderer.setStyle(element, 'width', '100%');
        this.renderer.setAttribute(element, 'data-type', 'media');
        this.renderer.setAttribute(element, 'data-self', 'media');

        this.renderer.appendChild(this.cameraOfInviter_component.publisherCameraDiv.nativeElement, element);

        let userMedia = {};
        if (!cameraOn)
            userMedia = { videoSource: null };

        OT.getUserMedia(userMedia).then((stream) => {
            let videoStream = null;

            if (cameraOn)
                videoStream = stream.getVideoTracks()[0];

            let options = { name: this.selectedUser['name'], videoSource: videoStream, audioSource: stream.getAudioTracks()[0], insertMode: 'replace', style: { nameDisplayMode: "off", buttonDisplayMode: "off", backgroundImageURI: this.userImg, videoDisabledDisplayMode: 'off' }, mirror: false, width: '100%', height: '100%', publishVideo: cameraOn, publishAudio: audioOn, audioBitrate: 20000 };

            //console.log('speakerCameraPublisher', obj.speakerCameraPublisher, this.speakerCameraPublisher)
            obj.speakerCameraPublisher = obj.publish(options, obj.speakerCameraPublisher, element, () => {
                this.disableIcon = false;
                let element = document.querySelector('[data-self="media"]');
                this.renderer.setAttribute(element, 'data-id', obj.speakerCameraPublisher.stream.streamId);

                element = this.renderer.createElement('div');
                this.renderer.setStyle(element, 'height', '100%');
                this.renderer.setStyle(element, 'width', '100%');
                this.renderer.setAttribute(element, 'data-type', 'media');
                // debugger;
                this.renderer.setAttribute(element, 'data-id', obj.speakerCameraPublisher.stream.streamId);

                let overlayElement = this.renderer.createElement('div');
                this.renderer.setAttribute(overlayElement, 'class', 'name-overlay-container');
                this.renderer.setAttribute(overlayElement, 'data-id', 'name-' + obj.speakerCameraPublisher.stream.streamId);
                let nameElement = this.renderer.createElement('div');
                this.renderer.setProperty(nameElement, 'innerHTML', this.selectedUser['name']);
                if (obj.speakerCameraPublisher.stream.hasVideo) {
                    this.renderer.setStyle(overlayElement, 'display', 'none');
                }
                overlayElement.append(nameElement);
                this.cameraOfInviter_component.publisherCameraDiv.nativeElement.prepend(overlayElement);

                overlayElement = this.renderer.createElement('div');
                this.renderer.setAttribute(overlayElement, 'class', 'name-overlay-container-corner');
                this.renderer.setAttribute(overlayElement, 'data-id', 'name-' + obj.speakerCameraPublisher.stream.streamId + '-corner');
                nameElement = this.renderer.createElement('div');
                this.renderer.setProperty(nameElement, 'innerHTML', this.selectedUser['name']);
                if (!obj.speakerCameraPublisher.stream.hasVideo) {
                    this.renderer.setStyle(overlayElement, 'display', 'none');
                }
                overlayElement.append(nameElement);
                this.cameraOfInviter_component.publisherCameraDiv.nativeElement.prepend(overlayElement);

                this.cameraOfInviter_component.publisherCameraDiv.nativeElement.append(element);
            });
        }).catch((error) => {
            this.mediaFlags = { audio: false, camera: false };
            obj.opentokService.handleError(error);
            this.disableIcon = false;
        });

        this.detectChanges();
    }

    invokeShareCamera(bool: boolean) {
        this.streamHasVideo = bool;
        if (this.speakerCameraPublisher) {
            this.speakerCameraPublisher = this.opentokService.unPublishPublisher(this.userSession, this.speakerCameraPublisher);
        }

        if (bool || this.streamHasAudio)
            this.shareMedia(this.streamHasAudio, bool);
    }

    invokeShareAudio(bool: boolean) {
        this.streamHasAudio = bool;
        if (this.speakerCameraPublisher) {
            if (!bool && !this.streamHasVideo) {
                this.speakerCameraPublisher = this.opentokService.unPublishPublisher(this.userSession, this.speakerCameraPublisher);
            }
            else
                this.speakerCameraPublisher.publishAudio(bool);
        }
        else if (bool || this.streamHasVideo)
            this.shareMedia(bool, this.streamHasVideo);
    }

    invokeShareMedia(bool: boolean) {
        this.streamHasAudio = bool;
        this.streamHasVideo = bool;
        this.shareMedia(bool, bool);
    }

    private publish(options: Object, publisher: OT.Publisher, element?: HTMLElement, callBack?: () => void): OT.Publisher {
        const obj = this;

        if (!element) {
            element = document.createElement('div');
        }

        publisher = OT.initPublisher(element, options,
            (error) => {
                if (error) {
                    this.mediaFlags = { audio: false, camera: false };
                    this.opentokService.handleError(error);
                    this.disableIcon = false;
                    this.detectChanges();
                }
                else {
                    obj.userSession.publish(publisher, (error) => {
                        if (error) {
                            obj.disableIcon = false;
                            this.mediaFlags = { audio: false, camera: false };
                            obj.opentokService.handleError(error);
                            obj.detectChanges();
                        }
                        if (callBack)
                            callBack();
                    });
                }
            }
        );

        publisher.on('streamCreated', (event) => {
            let userStream = this.userMap.get(this.selectedUser['_id'])['streams'];
            userStream['media'] = event.stream;
        });

        publisher.on('streamDestroyed', (event) => {
            let userStream = this.userMap.get(this.selectedUser['_id'])['streams'];
            userStream['media'] = '';
            if (event.stream)
                this.opentokService.removeOverlayName(event.stream.streamId, this.renderer);
        });
        return publisher;
    }

    public subscribeToMedia(stream: OT.Stream, container: any, subscribeToCamera: boolean = true, firstUser: boolean = true, insertMode: string = 'append', callBack?: () => void): OT.Subscriber {
        return this.opentokService.subscribeToMedia(stream, container, this.userSession, this.renderer, this.retrySubscribingToMedia, { width: 320, height: 240 }, insertMode, callBack, subscribeToCamera, firstUser);
    }

    private countDown() {
        this.counterSubscription = this.opentokService.getVideoCallCounter(this.tick).subscribe(() => {
            this.counter--;
            if (this.counter <= 0) {
                this.hangup();
            }
            this.detectChanges();
        });


        // let countDownTotal = 2 * 1000 * 60;

        // // Update the count down every 1 second
        // this.countDownInterval = setInterval(() => {

        //     countDownTotal = countDownTotal - 1000;
        //     let distance = countDownTotal;

        //     // Time calculations for days, hours, minutes and seconds
        //     // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        //     // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        //     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //     let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //     // Output the result in an element with id="demo"
        //     this.countDownTextMin = minutes;
        //     this.countDownTextSec = seconds;

        //     // If the count down is over, write some text 
        //     if (distance < 0) {
        //         // clearInterval(this.countDownInterval);
        //         // this.countDownTextSec = "Time is up!";
        //         this.hangup();
        //     }
        //     this.detectChanges();
        // }, 1000);
    }
}
