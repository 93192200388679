import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-iframe-url',
	templateUrl: './iframe-url.component.html',
	styleUrls: ['./iframe-url.component.scss']
})
export class IframeUrlComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public loading: boolean = false;
	public iframe_html: SafeResourceUrl = this.transform('/#/empty');
	public iframeHeight: string = '100%';
	public _iframeParentHeight: string = '100%';
	@Input()
	set iframeParentHeight(iframeParentHeight: string) {
		if (iframeParentHeight)
			this._iframeParentHeight = iframeParentHeight + 'vh';
	}
	@Input() iframeParentWidth: string = '100%';
	public _iframeLink = '';
	@Input()
	set iframeLink(iframeLink: any) {
		this._iframeLink = iframeLink;
		if (iframeLink) {
			this.loadData(iframeLink);
		} else {
			this.iframe_html = this.transform('/#/empty');
		}
	}
	get iframeLink(): any {
		return this._iframeLink;
	}
	@ViewChild('iframeMain') iframeMain: ElementRef;
	@Input() iframePadding: string = '';

	constructor(
		private requestService: RequestService, private sanitizer: DomSanitizer,
		private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService) {
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
				}
			})
		);
	}
	public loadData(url) {
		this.iframe_html = this.transform(url);
		this.loading = true;
	}
	onload(ev: Event) {
		// this.resizeIframe();
		this.loading = false;
	}
	public pageY(elem) {
		return elem.offsetParent ? (elem.offsetTop + this.pageY(elem.offsetParent)) : elem.offsetTop;
	}
	public resizeIframe() {
		let height = document.documentElement.clientHeight;
		height -= this.pageY(this.iframeMain.nativeElement) + 20;
		height = (height < 0) ? 0 : height;
		this.iframeHeight = height + 'px';
		if (height < 400) {
			this.iframeHeight = '400px';
		}
	}
	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
