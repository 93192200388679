import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, AfterViewChecked, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TokBoxService } from 'src/app/shared';

@Component({
    selector: 'app-camera-play',
    templateUrl: './camera-play.component.html',
    styleUrls: ['./camera-play.component.scss']
})

export class SpeakerCameraComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    changeDetectorRef: ChangeDetectorRef;
    // currentAttendee: any = undefined;
    // firstTime: boolean = true;
    audioOn: boolean = false;
    cameraOn: boolean = false;
    hovered: boolean = false;

    // @Input() attendeeMap: Map<string, object>;
    // @Input() selectedUser: any;
    @Input() disableIcon: boolean = false;
    @Input() session: OT.Session;
    @Input() iconSize: string = '5em';
    // @Input() toggleIconSize: string = '3em';
    @Input() labelSize: string = '1em';
    @Input() firstTime: boolean = true;
    // @Input() cameraPublisher: OT.Publisher;
    @Input() disableIcons: boolean = false;
    @Input()
    set stopMediaFlag(stopMediaFlag: any) {
        if (stopMediaFlag)
            this.stopMedia();
    }
    @Input()
    set mediaFlags(mediaFlags: any) {
        if (mediaFlags) {
            if (mediaFlags.hasOwnProperty('audio'))
                this.audioOn = mediaFlags.audio;
            if (mediaFlags.hasOwnProperty('camera'))
                this.cameraOn = mediaFlags.camera;
        }
    }

    @Output('permitCamera') permitCamera = new EventEmitter<boolean>();
    @Output('permitAudio') permitAudio = new EventEmitter<boolean>();
    @Output('permitMedia') permitMedia = new EventEmitter<boolean>();

    @ViewChild('publisherCameraDiv') publisherCameraDiv: ElementRef;
    @ViewChild('camera') cameraDiv: ElementRef;

    constructor(
        private opentokService: TokBoxService,
        private ref: ChangeDetectorRef
    ) {
        this.changeDetectorRef = ref;
    }

    ngOnInit() {
        // this.subscriptions.push(this.opentokService.userCapabilitiesMap.subscribe(value => {
        //     if (value != undefined) {
        //         this.attendeeMap = value;
        //         // this.currentAttendee = this.attendeeMap.get(this.selectedUser['_id']);
        //     }
        // }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    toggleCamera(bool: boolean) {
        if (!this.disableIcon) {
            // if (!this.cameraPublisher) {
            //     // this.audioOn = bool;
            //     this.cameraOn = bool;
            //     // this.permitMedia.emit(bool);
            // }
            // else {
            if (this.firstTime) {
                this.audioOn = bool;
                this.cameraOn = bool;
                this.permitMedia.emit(bool);
                this.firstTime = false;
            }
            else {
                this.cameraOn = bool;
                this.permitCamera.emit(bool);
            }
        }
    }

    toggleAudio(bool: boolean) {
        if (!this.disableIcon) {
            this.firstTime = false;
            this.audioOn = bool;
            this.permitAudio.emit(bool);
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    toggleCameraActions(bool: boolean) {
        this.hovered = bool;
        this.detectChanges();
    }

    stopMedia() {
        this.toggleAudio(false);
        this.toggleCamera(false);
    }
}
