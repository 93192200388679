import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { LoaderService } from '../../../../shared/services';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '../../../../shared/helpers/utils';
// import { ModalVideosDialogComponent } from '../../../../shared/components/custom-videos-dialog/custom-videos-dialog.component';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'linkdescription-block',
    templateUrl: './linkdescription-block.component.html',
    styleUrls: ['../tileblocks.component.scss', './linkdescription-block.component.scss']
})
export class LinkDescriptionBlockComponent implements BlockComponent {
  @Input() block: any;
  @Output() linkDescriptionView = new EventEmitter<any>();
  constructor(public sanitizer: DomSanitizer, public utils: Utils, private loaderService: LoaderService,
  public dialog: MatDialog) {}

  public onChange( data ) {
      // const data = editor.getData();
      this.block.data.text = data;
      this.linkDescriptionView.emit({'action': 'linkDescriptionView', block: this.block});
  }
}
