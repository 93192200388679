import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { GlobalService } from './services/global.service';

import { AlertActionEntityDialogComponent, SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent, ActionNotificationComponent, ConfirmCkeditorEntityDialogComponent, ConfirmInputEntityDialogComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ModalUserDialogComponent, ModalDialogComponent, ModalPasswordDialogComponent, ModalUserViewDialogComponent, CustomSelectComponent, CustomTagsComponent, TileWidgetComponent, ModalIframeHeaderDialogComponent, WidgetComponent, ConfirmSelectEntityDialogComponent } from './components';

import { CkeditorBlockComponent, WidgetDummyComponent, TextBlockComponent, LinkDescriptionBlockComponent, VideoBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, ShareBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, AttendanceBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, ModalGalleryDialogComponent } from './components';

import { CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent, CustomMetadataTableComponent, CustomMultipleSelectComponent, CustomSelectCreateAutocompleteDialogComponent, ModalPreviewDialogComponent, TilesListComponent, IframeUrlComponent, TileAnswersComponent, TileAnswersPercentageComponent, ModalTileCreateDialogComponent, TileDataComponent, LectureTilesListComponent, ModalLayoutDialogComponent, TileSelectDialogComponent, SliderComponent, MapAreaComponent, BillboardSelectDialogComponent, CustomSelectDialogComponent, LayoutRowDataComponent, ModalCropDialogComponent, MarginViewComponent, CustomDateTimeComponent } from './components';
import { ClickOutSideDirective } from './directives';

import { Utils } from './helpers/utils';

import { CKEditorModule } from 'ngx-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileModalDialogComponent } from './components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';
import { GlobalChatComponent } from './components/global-chat/global-chat.component';
import { SpeakerCameraComponent } from '../pages/rooms/components/session-page/camera-play/camera-play.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AgendaComponent } from './components/agenda/agenda.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false }),
        DeviceDetectorModule
    ],
    providers: [
        // GlobalService
        Utils
    ],
    entryComponents: [
        SnackBarComponent,
        AlertActionEntityDialogComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ConfirmCkeditorEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalViewDialogComponent,
        EnumViewComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        GlobalChatComponent,
        AgendaComponent,
        SpeakerCameraComponent,
        CustomTagsComponent,
        TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        WidgetComponent,
        WidgetDummyComponent,
        VideoBlockComponent,
        PictureBlockComponent,
        DisqusBlockComponent,
        SocialFeedBlockComponent,
        CalendarBlockComponent,
        ShareBlockComponent,
        PatientsBlockComponent,
        InquiryBlockComponent,
        SurveyBlockComponent,
        QuestionnaireBlockComponent,
        QuestionnaireSubOptionComponent,
        DescriptionSubOptionComponent,
        StartWrapperBlockComponent,
        AddSpaceComponent,
        FormTitleBlockComponent,
        QuestionsBlockComponent,
        AttendanceBlockComponent,
        ConfirmationBlockComponent,
        PasswordBlockComponent,
        NextBlockComponent,
        FormPhotoComponent,
        FormDocumentComponent,
        PainLevelComponent,
        DrawToolBlockComponent,
        PhysicianBlockComponent,
        EndWrapperBlockComponent,
        FillBlockComponent,
        NotesBlockComponent,
        ButtonsBlockComponent,
        ContactUsBlockComponent,
        PlacefullBlockComponent,
        AddToCartBlockComponent,
        CartBlockComponent,
        BlanksFormBlockComponent,
        ExclusiveUrlBlockComponent,
        FileUploadBlockComponent,
        PushpayBlockComponent,
        ThreedCartBlockComponent,
        BlogsBlockComponent,
        ChatBlockComponent,
        AccountBlockComponent,
        ProfileBlockComponent,
        TextBlockComponent,
        LinkDescriptionBlockComponent,
        CkeditorBlockComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        TilesListComponent,
        IframeUrlComponent,
        TileAnswersComponent,
        TileAnswersPercentageComponent,
        ModalTileCreateDialogComponent,
        TileDataComponent,
        LectureTilesListComponent,
        ModalLayoutDialogComponent,
        TileSelectDialogComponent,
        BillboardSelectDialogComponent,
        CustomSelectDialogComponent,
        SliderComponent,
        MapAreaComponent,
        MarginViewComponent,
        LayoutRowDataComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent
    ],
    declarations: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        EnumViewComponent,
        ActionNotificationComponent,
        ConfirmCkeditorEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        GlobalChatComponent,
        AgendaComponent,
        SpeakerCameraComponent,
        CustomTagsComponent,
        TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        WidgetComponent,
        WidgetDummyComponent,
        VideoBlockComponent,
        PictureBlockComponent,
        DisqusBlockComponent,
        SocialFeedBlockComponent,
        CalendarBlockComponent,
        ShareBlockComponent,
        PatientsBlockComponent,
        InquiryBlockComponent,
        SurveyBlockComponent,
        QuestionnaireBlockComponent,
        QuestionnaireSubOptionComponent,
        DescriptionSubOptionComponent,
        StartWrapperBlockComponent,
        AddSpaceComponent,
        FormTitleBlockComponent,
        QuestionsBlockComponent,
        AttendanceBlockComponent,
        ConfirmationBlockComponent,
        PasswordBlockComponent,
        NextBlockComponent,
        FormPhotoComponent,
        FormDocumentComponent,
        PainLevelComponent,
        DrawToolBlockComponent,
        PhysicianBlockComponent,
        EndWrapperBlockComponent,
        FillBlockComponent,
        NotesBlockComponent,
        ButtonsBlockComponent,
        ContactUsBlockComponent,
        PlacefullBlockComponent,
        AddToCartBlockComponent,
        CartBlockComponent,
        BlanksFormBlockComponent,
        ExclusiveUrlBlockComponent,
        FileUploadBlockComponent,
        PushpayBlockComponent,
        ThreedCartBlockComponent,
        BlogsBlockComponent,
        ChatBlockComponent,
        AccountBlockComponent,
        ProfileBlockComponent,
        TextBlockComponent,
        LinkDescriptionBlockComponent,
        CkeditorBlockComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        TilesListComponent,
        IframeUrlComponent,
        TileAnswersComponent,
        TileAnswersPercentageComponent,
        ModalTileCreateDialogComponent,
        TileDataComponent,
        LectureTilesListComponent,
        ModalLayoutDialogComponent,
        TileSelectDialogComponent,
        BillboardSelectDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        SliderComponent,
        MapAreaComponent,
        MarginViewComponent,
        LayoutRowDataComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent
    ],
    exports: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        ConfirmCkeditorEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        GlobalChatComponent,
        AgendaComponent,
        SpeakerCameraComponent,
        CustomTagsComponent,
        TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        WidgetComponent,
        WidgetDummyComponent,
        VideoBlockComponent,
        PictureBlockComponent,
        DisqusBlockComponent,
        SocialFeedBlockComponent,
        CalendarBlockComponent,
        ShareBlockComponent,
        PatientsBlockComponent,
        InquiryBlockComponent,
        SurveyBlockComponent,
        QuestionnaireBlockComponent,
        QuestionnaireSubOptionComponent,
        DescriptionSubOptionComponent,
        StartWrapperBlockComponent,
        AddSpaceComponent,
        FormTitleBlockComponent,
        QuestionsBlockComponent,
        AttendanceBlockComponent,
        ConfirmationBlockComponent,
        PasswordBlockComponent,
        NextBlockComponent,
        FormPhotoComponent,
        FormDocumentComponent,
        PainLevelComponent,
        DrawToolBlockComponent,
        PhysicianBlockComponent,
        EndWrapperBlockComponent,
        FillBlockComponent,
        NotesBlockComponent,
        ButtonsBlockComponent,
        ContactUsBlockComponent,
        PlacefullBlockComponent,
        AddToCartBlockComponent,
        CartBlockComponent,
        BlanksFormBlockComponent,
        ExclusiveUrlBlockComponent,
        FileUploadBlockComponent,
        PushpayBlockComponent,
        ThreedCartBlockComponent,
        BlogsBlockComponent,
        ChatBlockComponent,
        AccountBlockComponent,
        ProfileBlockComponent,
        TextBlockComponent,
        LinkDescriptionBlockComponent,
        CkeditorBlockComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        TilesListComponent,
        IframeUrlComponent,
        TileAnswersComponent,
        TileAnswersPercentageComponent,
        ModalTileCreateDialogComponent,
        TileDataComponent,
        LectureTilesListComponent,
        ModalLayoutDialogComponent,
        TileSelectDialogComponent,
        BillboardSelectDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        SliderComponent,
        MapAreaComponent,
        MarginViewComponent,
        LayoutRowDataComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent
    ]
})
export class LayoutComponentModule { }
