import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface ViewDialogData {
	title: string;
	dataType: string;
	dataTypeTitle: string;
	filters: any;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-custom-select-dialog-modal',
	templateUrl: './custom-select-dialog.component.html',
	styleUrls: ['./custom-select-dialog.component.scss']
})
export class CustomSelectDialogComponent implements OnInit {
	public itemName = '';
	constructor(public dialogRef: MatDialogRef<CustomSelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	selectItemCheck(data) {
		this.dialogRef.close(data);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
