import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class Config {
	public mainLink = 'aetherhealthcare.com';
	public mainEmail = 'support@interactivelife.com';
	public logoTopLogin = 'assets/AHn.png';
	public logoTop = 'assets/AHn.png';
	public logoTopLogo = 'assets/favicon.png';
	public logoLoginBotton = 'assets/logos.png';
	public mainName = 'Process';
	public instancesName = 'Instances';
	public definitionsName = 'Definitions';
	public taskNames = 'Tasks';
	public taskName = 'Task';
	public PatientName = 'Patient';
	public DashboardName = 'Dashboard';
}
