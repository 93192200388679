import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { TileSelectDialogComponent } from '../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { BillboardSelectDialogComponent } from '../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
import { ConfirmSelectEntityDialogComponent } from '../../../shared/components/modals';

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-layout-row-data',
  templateUrl: './layout-row-data.component.html',
  styleUrls: ['./layout-row-data.component.scss']
})
export class LayoutRowDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public orgType = environment.orgType;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public columnsLength: number = 1;
  public columnsLengthMobile: number = 1;
  public isSubmitted: boolean = true;
  public showMobile: boolean = false;
  public componentsName: any = {
    camera: {
      displayName: this.translate.instant('Attendee') + ' Camera',
      fixed: false,
      content: {
        name: 'camera',
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        align: 'left'
      }
    },
    'guest-camera': {
      displayName: this.translate.instant('Guest') + ' Camera',
      fixed: true,
      content: {
        name: 'guest-camera',
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        metadata: {
          id: '',
          name: ''
        },
        width: '100',
        align: 'left'
      }
    },
    attendees: {
      displayName: 'Attendees',
      fixed: false,
      content: {
        name: 'attendees',
        active: true,
        expanded: false,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: '30VH',
        backgroundColor: '#ffffff',
        align: 'left'
      }
    },
    defaultvideo: {
      displayName: 'Default Video',
      fixed: false,
      content: {
        name: 'defaultvideo',
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: 30,
        frame: '',
        url: '',
        align: 'left'
      }
    },
    screenshare: {
      displayName: 'Screenshare',
      fixed: false,
      content: {
        name: 'screenshare',
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        message: '',
        align: 'left'
      }
    },
    'presenter-camera': {
      displayName: this.translate.instant('Lead') + ' Camera',
      fixed: true,
      content: {
        name: 'presenter-camera',
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        align: 'left'
      }
    },
    chat: {
      displayName: 'Chat',
      fixed: false,
      content: {
        name: 'chat',
        active: true,
        expanded: false,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: 30,
        backgroundColor: '#ffffff',
        align: 'left'
      }
    },
    questions: {
      displayName: 'Questions',
      fixed: false,
      content: {
        name: 'questions',
        active: true,
        expanded: false,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: 30,
        backgroundColor: '#ffffff',
        align: 'left'
      }
    },
    tile: {
      displayName: 'Tile',
      fixed: true,
      content: {
        name: 'tile',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: 20,
        align: 'left'
      }
    },
    billboard: {
      displayName: 'Billboard',
      fixed: true,
      content: {
        name: 'billboard',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        margin: '0VH 0VW 0VH 0VW',
        width: '100',
        height: 20,
        align: 'left'
      }
    }
  };
  public componentsNameKeys: any = Object.keys(this.componentsName);
  public garbageList: any[] = [];
  public componentsList: any[] = [];
  public mobilecomponentsList: any[] = [];
  public defaultSetting = {
    version: 1,
    mobile: {
      columns: [
        {
          width: 20,
          rowGap: 0,
          components: [
            {
              name: 'camera',
              active: true,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              align: 'left'
            },
            {
              name: 'attendees',
              active: true,
              expanded: false,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              height: 30,
              backgroundColor: '#ffffff',
              align: 'left'
            },
            {
              name: 'screenshare',
              active: true,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              message: '',
              align: 'left'
            },
            {
              name: 'presenter-camera',
              active: true,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              align: 'left'
            },
            {
              name: 'chat',
              active: true,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              height: 30,
              expanded: false,
              backgroundColor: '#ffffff',
              align: 'left'
            },
            {
              name: 'tile',
              metadata: {
                id: '',
                name: ''
              },
              active: true,
              margin: '0VH 0VW 0VH 0VW',
              width: 100,
              height: 20,
              align: 'left'
            }
          ]
        }
      ],
      gap: '20px',
      enableScreenShareButton: true,
      enableFullScreen: true,
      enableCaptureScreen: true,
      enableRaiseHand: true
    },
    columns: [
      {
        width: 20,
        rowGap: 0,
        rows: [
          {
            halign: 'space-between',
            valign: 'center',
            components: [
              {
                name: 'camera',
                active: true,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                align: 'left'
              }
            ]
          },
          {
            components: [
              {
                name: 'attendees',
                active: true,
                expanded: false,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                height: 30,
                backgroundColor: '#ffffff',
                align: 'left'
              }
            ]
          }
        ]
      },
      {
        width: 60,
        rowGap: 0,
        rows: [
          {
            halign: 'space-between',
            valign: 'center',
            components: [
              {
                name: 'screenshare',
                active: true,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                message: '',
                align: 'left'
              }
            ]
          }
        ]
      },
      {
        width: 20,
        rowGap: 0,
        rows: [
          {
            halign: 'space-between',
            valign: 'center',
            components: [
              {
                name: 'presenter-camera',
                active: true,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                align: 'left'
              }
            ]
          },
          {
            components: [
              {
                name: 'chat',
                active: true,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                height: 30,
                expanded: false,
                backgroundColor: '#ffffff',
                align: 'left'
              }
            ]
          },
          {
            components: [
              {
                name: 'tile',
                metadata: {
                  id: '',
                  name: ''
                },
                active: true,
                margin: '0VH 0VW 0VH 0VW',
                width: 90,
                height: 20,
                align: 'left'
              }
            ]
          }
        ],
      }
    ],
    gap: '20px',
    enableScreenShareButton: true,
    enableFullScreen: true,
    enableCaptureScreen: true,
    enableRaiseHand: true
  };
  public settingObject = undefined;
  public displayingVersion = undefined;
  public metaFieldSetting = undefined;
  public originalTableSetting: any = undefined;
  @Input() templateSettings: any = undefined;
  @Input() templateDisabled: boolean = false;
  @Input() data: any = undefined;
  @Output() closeModalReturn = new EventEmitter<any>();
  @Output() showMobileReturn = new EventEmitter<boolean>();

  constructor(
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.buildSetting();
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = data.results;
          this.metaFieldSetting = this.buildMetaSetting(data.results);
          let newFields = data.results.fields;
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.data.modalSetting.fields = newFields;
          if (this.templateDisabled || this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          } else {
            this.data.data = this.getEmptyObject();
            this.cleanSettings();
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
        tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
      } else if (col.type === 'table') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
    }
    return dataObject;
  }
  loadDefaultSettings() {
    this.data.data['settings'] = JSON.parse(JSON.stringify(this.defaultSetting));
    this.cleanSettings();
  }
  loadTemplateSettings() {
    if (this.templateDisabled && this.templateSettings) {
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.templateSettings));
    } else {
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.defaultSetting));
    }
    this.cleanSettings();
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
        dataId = dataId + '/' + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = data.results;
          if (this.templateDisabled) {
            if (!this.data.data.settings || (this.data.data.settings && this.data.data.settings === '')) {
              if (this.templateSettings) {
                this.data.data['settings'] = JSON.parse(JSON.stringify(this.templateSettings));
              } else {
                this.loadDefaultSettings();
              }
            }
            this.cleanSettings();
          } else {
            this.cleanSettings();
          }
        }
        this.loading = false;
      });
    }
  }
  removeMobileCmp(index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['mobile']));
    currentSettings['columns'][index]['components'].splice(subIndex, 1);
    this.data.data['settings']['mobile'] = JSON.parse(JSON.stringify(currentSettings));
    this.cleanMobileSettings();
  }
  removeCmp(index, rowIndex, subIndex,) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['rows'][rowIndex]['components'].splice(subIndex, 1);
    this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
    this.cleanSettings();
  }
  public cleanSettings() {
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']));
    let componentsList = [];
    let selectedComponents = [];
    this.columnsLength = currentSettings['columns'].length;
    if (currentSettings.hasOwnProperty('version') && currentSettings.version === this.defaultSetting.version) {
      for (let col of currentSettings['columns']) {
        for (let row of col['rows']) {
          for (let cmp of row['components']) {
            selectedComponents.push(cmp.name);
          }
        }
      }
      for (let key of this.componentsNameKeys) {
        if (selectedComponents.indexOf(key) === -1 || this.componentsName[key]['fixed']) {
          componentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
        }
      }
      // console.log('this.data.datasettings', this.data.data['settings']);
      this.componentsList = JSON.parse(JSON.stringify(componentsList));
    }
    this.settingObject = JSON.parse(JSON.stringify(currentSettings));
    this.cleanMobileSettings();
  }
  public cleanMobileSettings() {
    if (!this.data.data['settings'].hasOwnProperty('mobile')) {
      this.data.data['settings']['mobile'] = JSON.parse(JSON.stringify(this.defaultSetting.mobile));
    }
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']['mobile']));
    let mobilecomponentsList = [];
    let selectedComponents = [];
    this.columnsLengthMobile = currentSettings['columns'].length;
    for (let col of currentSettings['columns']) {
      for (let cmp of col['components']) {
        selectedComponents.push(cmp.name);
      }
    }
    for (let key of this.componentsNameKeys) {
      if (selectedComponents.indexOf(key) === -1 || this.componentsName[key]['fixed']) {
        mobilecomponentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
      }
    }
    // console.log('this.data.datasettings', this.data.data['settings']);
    this.mobilecomponentsList = JSON.parse(JSON.stringify(mobilecomponentsList));
    this.settingObject['mobile'] = JSON.parse(JSON.stringify(currentSettings));
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = { _id: val._id, name: val.name };
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (data && data[col.name] && data[col.name].length === 0) {
              return false;
            }
          } else {
            if (data && data[col.name] && data[col.name] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  public saveData(type) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject)); // adding the selected form
      if (this.templateDisabled) {
        this.loading = true;
        let dataToSave = { _id: this.data.data['_id'], name: this.data.data['name'], settings: this.data.data['settings'] };
        this.errorMessage = '';
        this.requestService.saveData(this.data.dataType, dataToSave, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
          }
          this.loading = false;
        });
      } else {
        if (this.validateObject(this.data.data)) {
          this.loading = true;
          //  this.loaderService.display(true);
          let useOrgId = false;
          if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
            useOrgId = this.data.modalSetting['useOrgId'];
          }
          this.errorMessage = '';
          this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            if (data) {
              if (type === 'create') {
                this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
                this.closeModal({ action: 'refresh' });
              } else if (type === 'edit') {
                this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
              }
              // this.closeModal({action: 'refresh'});
            }
            this.loading = false;
          }, useOrgId);
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to set all mandatory fields'), this.translate.instant('Dismiss'));
        }
      }
    }
  }
  closeModal(data): void {
    this.closeModalReturn.emit(data);
  }
  setShowMobileReturn(e): void {
    if (e && e.screenX > 0) {
      //console.log('setShowMobileReturn', e);
      this.showMobile = !this.showMobile;
      this.showMobileReturn.emit(this.showMobile);
    }
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    } else {
      //console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            newObj[col.name] = [];
          } else {
            newObj[col.name] = '';
          }
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'json') {
          if (col.name === 'settings') {
            newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSetting));
          } else {
            newObj[col.name] = [];
          }
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('customSettings')) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  drop(event: CdkDragDrop<string[]>, isMain: boolean = false) {
    console.log('event', event);
    console.log(' event.previousContainer.id', event.previousContainer.id);
    console.log('isMain', isMain);
    console.log('fixed', this.componentsName[event.previousContainer.data[event.previousIndex]['name']]['fixed']);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (!isMain) {
        if (this.componentsName[event.previousContainer.data[event.previousIndex]['name']]['fixed']) {
          if(event.previousContainer.id === 'mainMenu'){
            copyArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          }else{
            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);
          }
        } else {
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        }
      } else {
        if (this.componentsName[event.previousContainer.data[event.previousIndex]['name']]['fixed']) {
          transferArrayItem(event.previousContainer.data,
            this.garbageList,
            event.previousIndex,
            0);
        } else {
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        }
      }
      console.log(event.previousContainer.data);
      console.log(event.container.data);
      console.log(this.garbageList);
    }
  }
  clearIt(e) {
    // e.preventDefault();
    // e.stopPropagation();
  }
  changeWidth(value, index) {
    let settingObject = JSON.parse(JSON.stringify(this.settingObject));
    let idx = 0;
    let total = 0;
    for (let col of settingObject['columns']) {
      if (idx !== index) {
        total = total + parseInt(col.width);
      }
      idx++;
    }
    let remaining = 100 - total;
    if (parseInt(value) <= remaining) {
      settingObject['columns'][index]['width'] = value;
    } else {
      settingObject['columns'][index]['width'] = remaining;
    }
    this.settingObject = settingObject;
    this.changeDetectorRef.detectChanges();
  }
  changeRows(value, index) {
    let e = parseInt(value);
    if (e > 0) {
      if (this.settingObject['columns'][index]['rows'].length < e) {
        this.settingObject['columns'][index]['rows'].push({
          halign: 'space-between',
          valign: 'center',
          components: []
        }
        );
      } else if (this.settingObject['columns'][index]['rows'].length > e) {
        this.settingObject['columns'][index]['rows'].splice(e, this.settingObject['columns'][index]['rows'].length - e);
      }
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject));
      this.cleanSettings();
    } else {
      this.cleanSettings();
    }
    this.changeDetectorRef.detectChanges();
  }
  changeWidthMobile(value, index) {
    let settingObject = JSON.parse(JSON.stringify(this.settingObject['mobile']));
    let idx = 0;
    let total = 0;
    for (let col of settingObject['columns']) {
      if (idx !== index) {
        total = total + parseInt(col.width);
      }
      idx++;
    }
    let remaining = 100 - total;
    if (parseInt(value) <= remaining) {
      settingObject['columns'][index]['width'] = value;
    } else {
      settingObject['columns'][index]['width'] = remaining;
    }
    this.settingObject['mobile'] = settingObject;
    this.changeDetectorRef.detectChanges();
  }
  changeRowWidth(value, index, rowIndex, subIndex) {
    let settingObject = JSON.parse(JSON.stringify(this.settingObject));
    let idx = 0;
    let total = 0;
    for (let col of settingObject['columns'][index]['rows'][rowIndex]['components']) {
      if (idx !== subIndex) {
        total = total + parseInt(col.width);
      }
      idx++;
    }
    let remaining = 100 - total;
    if (parseInt(value) <= remaining) {
      settingObject['columns'][index]['rows'][rowIndex]['components'][subIndex]['width'] = value;
    } else {
      settingObject['columns'][index]['rows'][rowIndex]['components'][subIndex]['width'] = remaining;
    }
    this.settingObject = settingObject;
    this.changeDetectorRef.detectChanges();
  }
  changeColumns(e) {
    // console.log('changeColumns', e);
    let currentColumnsLength = this.settingObject['columns'].length;
    if (e > 0 && e < 6) {
      this.columnsLength = e;
      if (currentColumnsLength < e) {
        let settingObject = JSON.parse(JSON.stringify(this.settingObject));
        let idx = 0;
        let remaining = 10;
        for (let col of settingObject['columns']) {
          let removeVal = Math.floor(remaining / currentColumnsLength);
          if (remaining - removeVal > 0) {
            settingObject['columns'][idx]['width'] = settingObject['columns'][idx]['width'] - removeVal;
            remaining = remaining - removeVal;
          } else {
            settingObject['columns'][idx]['width'] = settingObject['columns'][idx]['width'] - remaining;
          }
          idx++;
        }
        this.settingObject['columns'].push({
          width: 10,
          rowGap: 0,
          rows: [
            {
              halign: 'space-between',
              valign: 'center',
              components: []
            }
          ]
        }
        );
      } else if (this.settingObject['columns'].length > e) {
        this.settingObject['columns'].splice(this.settingObject['columns'].length - 1, 1);
      }
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject));
      this.cleanSettings();
    } else {
      this.cleanSettings();
    }
  }
  editTile(index, rowIndex, subIndex) {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = result['_id'];
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = result['title'];
        } else {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
      }
    });
  }
  editRowH(index, rowIndex) {
    let settingObject = Object.assign({}, this.settingObject);
    let horizontalAllignList = [
      // { _id: 'none', text: 'none' },
      { _id: 'start', text: 'start' },
      { _id: 'center', text: 'center' },
      { _id: 'end', text: 'end' },
      { _id: 'space-around', text: 'space-around' },
      { _id: 'space-between', text: 'space-between' },
      { _id: 'space-evenly', text: 'space-evenly' }
    ];
    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Alter') + ' ' + this.translate.instant('Row') + ' ' + (rowIndex + + 1) + ' Horizontal Alignment',
        data: settingObject['columns'][index]['rows'][rowIndex]['halign'],
        dataList: horizontalAllignList,
        placeholder: 'Select Horizontal Alignment',
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        currentSettings['columns'][index]['rows'][rowIndex]['halign'] = result['_id'];
        this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
      }
    });
  }
  editRowV(index, rowIndex) {
    let settingObject = Object.assign({}, this.settingObject);
    let verticalAllignList = [
      // { _id: 'none', text: 'none' },
      { _id: 'start', text: 'start' },
      { _id: 'center', text: 'center' },
      { _id: 'end', text: 'end' },
      { _id: 'stretch', text: 'stretch' }
    ];
    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Alter') + ' ' + this.translate.instant('Row') + ' ' + (rowIndex + + 1) + ' Vertical Alignment',
        data: settingObject['columns'][index]['rows'][rowIndex]['valign'],
        dataList: verticalAllignList,
        placeholder: 'Select Vertical Alignment',
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        currentSettings['columns'][index]['rows'][rowIndex]['valign'] = result['_id'];
        this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
      }
    });
  }
  editTileMobile(index, subIndex) {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject['mobile']));
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings']['mobile'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanMobileSettings();
      }
    });
  }
  editBillboard(index, rowIndex, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
      }
    });
  }
  editBillboardMobile(index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['mobile']));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: currentSettings['columns'][index]['components'][subIndex],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings']['mobile'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanMobileSettings();
      }
    });
  }
  getSelectedUsers(type) {
    let alreadySelected = [];
    let settingObject = Object.assign({}, this.settingObject);
    for (let col of settingObject['columns']) {
      for (let row of col['rows']) {
        for (let comp of row['components']) {
          if (comp.name === type && comp.metadata.hasOwnProperty('id') && comp.metadata.id !== '') {
            alreadySelected.push(comp.metadata.id);
          }
        }
      }
    }
    return alreadySelected;
  }
  getSelectedUsersMobile(type) {
    let alreadySelected = [];
    let settingObject = Object.assign({}, this.settingObject);
    for (let col of settingObject['columns']) {
      for (let comp of col['components']) {
        if (comp.name === type && comp.metadata.hasOwnProperty('id') && comp.metadata.id !== '') {
          alreadySelected.push(comp.metadata.id);
        }
      }
    }
    return alreadySelected;
  }
  editGuest(index, rowIndex, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));

    let alreadySelected = this.getSelectedUsers('guest-camera');

    let guestsList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === 'guest' && !alreadySelected.includes(dt._id)) {
          guestsList.push({ _id: dt._id, text: dt.name });
        }
      }
    }

    // console.log('guestsList', guestsList);
    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Guest') + ' Camera',
        data: currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex],
        dataList: guestsList,
        placeholder: 'Select ' + this.translate.instant('Guest'),
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['rows'][rowIndex]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
      }
    });
  }
  editGuestMobile(index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['mobile']));

    let alreadySelected = this.getSelectedUsersMobile('guest-camera');

    let guestsList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === 'guest' && !alreadySelected.includes(dt._id)) {
          guestsList.push({ _id: dt._id, text: dt.name });
        }
      }
    }

    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Guest') + ' Camera',
        data: currentSettings['columns'][index]['components'][subIndex],
        dataList: guestsList,
        placeholder: 'Select ' + this.translate.instant('Guest'),
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.data.data['settings']['mobile'] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanMobileSettings();
      }
    });
  }
}
