import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { TileDataComponent } from '../tile-data/tile-data.component';
import { urlSafeBase64Encoding } from '../../../shared/helpers';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-tile-create-dialog-modal',
	templateUrl: './tile-create-dialog.component.html',
	styleUrls: ['./tile-create-dialog.component.scss']
})
export class ModalTileCreateDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	@ViewChild('tileData') tile_data: TileDataComponent;
	constructor(
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalTileCreateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	save(): void {
		this.tile_data.save();
		// this.dialogRef.close(data);
	}
	saveReturn(e): void {
		if (e) {
			this.closeModal(e);
		}
	}
}
