import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, interval, fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, MenuConfigService, LayoutUtilsService } from './shared/services';
import { environment } from '../environments/environment';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { MenuConfig } from './menu.config';
import { ErrorEntityDialogComponent } from './shared/components/modals';
import { guid } from './shared/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public aliveAppSubscriptions: any = undefined;
  public aliveCheckAppSubscriptions: any = undefined;
  public uniqueIDIdentifier: string = guid();
  public uniqueID: string = JSON.parse(JSON.stringify(this.uniqueIDIdentifier));
  private aliveAppIntervalMinutes: number = environment.aliveAppIntervalMinutes;
  private aliveCheckAppIntervalMinutes: number = environment.aliveCheckAppIntervalMinutes;
  public showLoader: boolean = false;
  public showError: boolean = true;
  private repeatIntervalSeconds: number = environment.repeatValidateSeconds; // make less when
  private dataSeenOnce: boolean = false;
  constructor(private menuConfigService: MenuConfigService, private meta: Meta, private title: Title, private loaderService: LoaderService, public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService, private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private storeService: StoreService, private renderer: Renderer2, private deviceService: DeviceDetectorService) {
    translate.setDefaultLang('en');

    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();

    // if (isMobile || isTablet) {
    //   this.router.navigate(['/mobile']);
    // }
    // else {
    this.getMe();
    // }
  }
  ngOnInit() {
    // const iliorgid = this.meta.getTag('name=ili-org-id');
    // this.title.setTitle('new title'); we can set title
    // console.log(iliorgid.content);
    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event) {
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          if (!this.dataSeenOnce) {
            this.getMe();
          }
        }
      }
      ));
    this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe(() => {
      if (this.requestService.authenticatedUser.getValue()) {
        this.validateMe();
      }
    }));
    const clicks$ = fromEvent(document, 'click');
    this.subscriptions.push(
      clicks$.subscribe((x) => {
        //console.log('Calling my service here');
        this.subscribeActivity();
      })
    );
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          // Show loading indicator
          this.loaderService.display(false);
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          // window.location.reload(); // uncomment later
          //console.log('NavigationError:', event.error);
        }
      })
    );
    this.subscriptions.push(
      this.logger.errorObject.subscribe((error) => {
        if (error) {
          // console.log('Global Error: ', error);
          // this.loaderService.display(false);
          if (this.showError) {
            this.showError = false;
            this.openAlert('An Error occured:' + error);
          }
        }
      })
    );
    this.menuConfigService.loadConfigs(new MenuConfig().configs);

  }
  // public updateGuid(){
  //   let guid = JSON.parse(localStorage.getItem('guid'));
  //   if (guid !== undefined && guid !== null) {
  //     this.uniqueID = guid;
  //     console.log('updateGuid this.uniqueID', this.uniqueID);
  //     console.log('updateGuid this.uniqueIDIdentifier', this.uniqueIDIdentifier);
  //   } else {
  //     console.log('change cashed id');
  //     localStorage.setItem('guid', JSON.stringify(this.uniqueID));
  //     console.log('updateGuid this.uniqueID', this.uniqueID);
  //     console.log('updateGuid this.uniqueIDIdentifier', this.uniqueIDIdentifier);
  //   }
  // }
  // public updateGuidFromIdentifier(){
  //   this.uniqueID = this.uniqueIDIdentifier;
  //   localStorage.setItem('guid', JSON.stringify(this.uniqueID));
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  subscribeActivity() {
    if (this.aliveAppSubscriptions) {
      this.aliveAppSubscriptions.unsubscribe();
    }
    if (this.aliveCheckAppSubscriptions) {
      this.aliveCheckAppSubscriptions.unsubscribe();
    }
    //console.log('subscribed', this.aliveCheckAppIntervalMinutes)
    this.aliveCheckAppSubscriptions = interval(1000 * 60 * this.aliveCheckAppIntervalMinutes).subscribe(() => {
      if (this.aliveAppSubscriptions) {
        this.aliveAppSubscriptions.unsubscribe();
      }
      if (this.aliveCheckAppSubscriptions) {
        this.aliveCheckAppSubscriptions.unsubscribe();
      }
      if (this.requestService.authenticatedUser.getValue()) {
        this.noActivity('No Activity', 'There was no activity for over ' + this.aliveCheckAppIntervalMinutes + ' minutes. Would you like to keep the session active?');
      }
    });
  };
  noActivity(title: string, msg: string) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['confirmText'] = 'Yes';
    alertSetting['declineText'] = 'No';
    // alertSetting['timerEvent'] = 120;

    const dialogRef = this.layoutUtilsService.alertActionElement(title, msg, alertSetting);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // if(res.action === 'declineText'){
        //   // this.subscribeActivity(); // do nothing
        // }else
        if (res.action === 'confirmText') {
          dialogRef.close();
        } else {
          this.requestService.logOutApi();
        }
      }
    });
    this.aliveAppSubscriptions = interval(1000 * 60 * (this.aliveAppIntervalMinutes - this.aliveCheckAppIntervalMinutes)).subscribe(() => {
      if (this.aliveAppSubscriptions) {
        this.aliveAppSubscriptions.unsubscribe();
      }
      if (dialogRef) {
        dialogRef.close();
      }
      this.requestService.logOutApi();
    });
  };
  public getMe() {
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let appId = JSON.parse(localStorage.getItem('a'));
      let locId = JSON.parse(localStorage.getItem('l'));
      let orgData = JSON.parse(localStorage.getItem('org'));
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.locId = locId;
      this.requestService.pageOrganization.next(orgData);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      this.requestService.updatePermissions(resource);
      this.dataSeenOnce = true;
      this.subscribeActivity();
      this.validateMe();
    } else {
      this.dataSeenOnce = false;
      this.requestService.logout();
    }
  }
  public validateMe() {
    this.requestService.getMe((data, error) => {
      if (error) {
        this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        sessionStorage.setItem('live', JSON.stringify(true));
        this.requestService.authenticatedUser.next(true);
        // valid
      } else {
        this.dataSeenOnce = false;
        this.requestService.logout();
      }
    });
  }
  public openAlert(message, title = 'Error') {
    const _title: string = title;
    const _description: string = message;

    const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      window.location.reload();
    });
  }
}
