import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RequestService, LayoutUtilsService, TokBoxService } from '../../../shared/services';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material';
import { ModalIframeDialogComponent } from '../../../shared/components/iframe-dialog/iframe-dialog.component';
import ImageMap from "image-map";
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-maparea',
  templateUrl: './maparea.component.html',
  styleUrls: ['./maparea.component.scss']
})
export class MapAreaComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public orgType = environment.orgType;
  public selectedUser: any;
  public errorMessage: string = '';
  public loading: boolean = false;
  public currentRoomUserRole = undefined;
  public oldMaps: string[] = ['map1', 'mathclassmap1', 'scienceclassmap1', 'schoolmap1', 'exhibitentry1' , 'lounge1', 'stellar-church-lobby', 'main-service-map', 'youth-service-map', 'sunday-school-map', 'prayer-room-map', 'children-service-map', 'main-map-demo-conf'];

  public mapAreaSelected = undefined;
  public currentSessionUserRole = undefined;
  masterModeratorId: string = undefined;
  leads: any = [];

  public _mapArea: any = undefined;
  @Input()
  set mapArea(mapArea: any) {
    this._mapArea = mapArea;
    if(mapArea){
      this.loadData(mapArea._id);
    }else{
      this.mapAreaSelected = undefined;
    }
  }
  get mapArea(): any {
    return this._mapArea;
  }


  @Input() _bgColor: string = undefined;
  @Input()
  set bgColor(bgColor: string) {
    this._bgColor = bgColor;
    if (bgColor) {
      const dom: any = document.querySelector('body');
      dom.style.backgroundColor = bgColor;
    }
  }
  get bgColor(): string {
    return this._bgColor;
  }

  constructor(
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, public opentokService: TokBoxService, public changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data
        }
      })
    );
    ImageMap('img[usemap]', 500);
  }
  ngAfterViewInit() {
    ImageMap('img[usemap]', 500);
  }
  imageLoaded() {
    ImageMap('img[usemap]', 500);
  }
  ngOnDestroy() {
    if (this.bgColor) {
      const dom: any = document.querySelector('body');
      dom.style.backgroundColor = '#f2f2f2'; // reset to default bg
    }
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  public loadData(id) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData('maparea', id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.mapAreaSelected = data.results;
          // this.imageLoaded();
        }else{
          this.mapAreaSelected = undefined;
        }
      });
    }
  }
  openMapLink(e, type, lnk) {
    if(e){
        e.stopPropagation();
        e.preventDefault();
    }
    console.log(type, lnk);
    if(type === 'page'){
      this.openSelfLink(undefined, '/'+ lnk);
    }else if (type === 'tile'){
      this.openIframe(undefined, lnk);
    }else if (type === 'room'){
      this.viewData(undefined, lnk);
    }else if (type === 'stellarcomm'){
      this.openStellarComm(undefined);
    }
    // this.router.navigate([lnk], { relativeTo: this.activatedRoute });

  }
  openSelfLink(e, lnk) {
    if(e){
        e.stopPropagation();
        e.preventDefault();
    }
    this.router.navigate([lnk], { relativeTo: this.activatedRoute });
  }
  openStellarComm(e) {
    if(e){
        e.stopPropagation();
        e.preventDefault();
    }
    if((this.selectedUser['isTextChat'] || this.selectedUser['isVideoChat']) && this.opentokService.globalSession && this.opentokService.globalSession['isConnected']()){
      this.opentokService.isOpenGlobalChat.next(true);
    }else{
      this.layoutUtilsService.showNotification(this.translate.instant('You need to enable video or audio chat from your profile'), this.translate.instant('Dismiss'));
    }
  }
  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
  public viewData(e, roomId) {
    if(e){
        e.stopPropagation();
        e.preventDefault();
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData('room', roomId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let roomData = data.results;
          this.currentRoomUserRole = this.requestService.getUserRoomRole(roomData);
          // console.log('this.currentRoomUserRole', this.currentRoomUserRole);
          // console.log('this.roomData.opentoall', this.roomData.opentoall);
          if (this.currentRoomUserRole === 'owner' || this.currentRoomUserRole === 'admin' || (roomData.opentoall && this.currentRoomUserRole === 'edit')) {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          } else {
            if (roomData.opentoall) {
              this.getSessions(roomData);
            } else {
              this.layoutUtilsService.showNotification(this.translate.instant('You have no access to this ') + this.translate.instant('room'), this.translate.instant('Dismiss'));
              // this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
            }
          }
        }
      });
    }
  }
  getSessions(roomData) {
    let roomId = roomData._id;
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { '$or': [{ "opentoall": true }, { "users._id": { "$in": [this.selectedUser._id] } }, { "createdBy": { "$eq": this.selectedUser._id } }] }]
    // };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'url', 'recordSource'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        let sessionsList = data.results;
        if (sessionsList && sessionsList.length > 0) {
          let currentSession = undefined;
          let currentDate: Moment = moment.utc();
          if (sessionsList.length > 1) {
            let activeSession = undefined;
            for (let lec of sessionsList) {
              if (lec.active) {
                activeSession = lec;
                break;
              }
            }
            currentSession = activeSession;
            if (!currentSession) {
              for (let lec of sessionsList) {
                let startD: Moment = moment.utc(lec.startsAt).local();
                let endD: Moment = moment.utc(lec.endAt).local();
                if ((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) {
                  activeSession = lec;
                  break;
                }
              }
            }
            currentSession = activeSession;
          } else {
            currentSession = sessionsList[0];
          }
          if (currentSession) {
            this.currentSessionUserRole = this.requestService.getUserSessionRole(currentSession);
            this.leads = this.requestService.getSessionRoleByType(currentSession, 'lead');
            this.masterModeratorId = this.requestService.getSessionHost(currentSession);
            if (this.leads.length == 0) {
              this.layoutUtilsService.showNotification('A ' + this.translate.instant('lead') + ' needs to be assigned', this.translate.instant('Dismiss'));
            }else{
              if (!this.masterModeratorId) {
                this.layoutUtilsService.showNotification(this.translate.instant('A host needs to be assigned'), this.translate.instant('Dismiss'));
              }else{
                if (currentSession.opentoall || this.currentSessionUserRole !== 'anonymous') {
                  if(!currentSession.active && currentSession.recordSource && currentSession.recordSource.trim() !== ''){
                    window.location.href = '/#/rooms/' + roomId + '/session/' + currentSession._id + '/record';
                  }else if(currentSession.url && currentSession.url.trim() !== ''){
                    window.open(currentSession.url.trim(), '_blank');
                  }else{
                    window.location.href = '/#/rooms/' + roomId + '/sessions/' + currentSession._id;
                    setTimeout(()=>{
                      window.location.reload();
                    }, 100);
                  }
                  return;
                } else {
                  this.layoutUtilsService.showNotification('Oops! This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
                }
              }
            }
          } else {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          }
        } else {
          // this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
        }
      }
    });
  }
  openIframe(e, tileId) {
    if(e){
        e.stopPropagation();
        e.preventDefault();
    }
    const dialogRef = this.dialog.open(ModalIframeDialogComponent, {
      width: '1600px',
      data: {
        //dataType: this.dataType,
        //title: this.translate.instant('Details'),
        data: this.requestService.getTileLink(undefined, tileId, true),
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
      }
    });
  }
}
