import { Injectable } from '@angular/core';
import { ResponseContentType, Http, RequestOptions, Headers } from '@angular/http';
import { Observable, Subscriber } from 'rxjs';
import { RequestService } from './request.service';

@Injectable()
export class UrlHelperService {
    constructor(private http: Http,private requestService: RequestService) {
    }

    get(url: string): Observable<any> {
        let options = new RequestOptions();
        options.headers = new Headers();
        options.headers.append('http-header', this.requestService.getToken());
        options.responseType = ResponseContentType.Blob;

        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;

            this.http
                .get(url, options)
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m.blob());
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}
